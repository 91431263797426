<template>
	<div>
		<section
				id="c2024-intro"
				class="main-row"
				>
				<b-container class="px-md-0">
								<!--<h2 v-html="$t('c2024.intro.subtitle')"></h2>-->
					<b-row class="m-0">
						<b-col
								class="text-center p-0"
								lg="11"
								xl="10"
								>
								<div class="title">
								<h1>
									<span v-html="$t('c2024.intro.title')"></span>
								</h1>
								<h2>
									<span v-html="$t('c2024.intro.subtitle')"></span>
								</h2>
								</div>
						</b-col>
					</b-row>
				</b-container>
		</section>
		<thermometer />
	</div>
</template>

<script>
	import thermometer from './Thermometer.vue';

	export default {
		components: {
			thermometer,
		},

		data() {
			return {
				c: '',
			};
		},
	};
</script>

<style lang="scss">
#c2024-intro {
	filter: drop-shadow(2px 5px 4px rgba(0, 0, 0, 0.2));
	padding: 1rem 0 3rem;
	min-height: 840px;
	position: relative;

	@media (max-width: 480px) {
		max-height: 600px;
		min-height: 600px;
	}


	@media (min-width: 1080px) {
		height: 85vh;
	}

	&::before,
	&::after {
		display: block;
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&::before {
		background: var(--f-f7);
		-webkit-clip-path: polygon(0 0, 0 100%, 100% 83%, 100% 0);
		clip-path: polygon(0 0, 0 100%, 100% 83%, 100% 0);

		@media (max-width: 768px) {
			-webkit-clip-path: polygon(0 0, 0 100%, 100% 93%, 100% 0);
			clip-path: polygon(0 0, 0 100%, 100% 93%, 100% 0);
		}
	}

	&::after {
		background: #daa76e;
		background-image: url('../../../../public/img/campaign/2024/intro.jpg');
		background-repeat: no-repeat;
		background-position: bottom;
		background-size: cover;
		-webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 88%);
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0 88%);

		@media (max-width: 768px) {
			-webkit-clip-path: polygon(0 0, 100% 0, 100% 98%, 0 95%);
			clip-path: polygon(0 0, 100% 0, 100% 98%, 0 95%);
		}
	}

	.container {
		position: absolute;
		z-index: 1;

		@media (max-width: 1240px) {
			left: 5vw;
			top: 30px;
		}

		@media (max-width: 762px) {
			top: 5rem;
		}

		@media (max-width: 560px) {
			top: 1rem;
		}

		@media (min-width: 1241px) {
			left: 60px;
			top: 20px;
		}
	}

	h1{
		font-size: 3.2rem;
		color: var(--f-d2);
		word-break: keep-all;
		@media (max-width: 1280px) {
		font-size: 2.5rem;
		}
		@media (max-width: 560px) {
			font-size: 2.4rem;
		}
	}

	.title {
		background: #dd6418cc;
		font-family: 'Tovari Sans';
		font-weight: bold;
		text-transform: uppercase;
		font-weight: 300;
		font-size: 2rem;
		margin: 1rem 0 1.5rem;
		padding: 1rem 8rem 1rem 8rem;
		border-radius: .5rem;
		line-height: 1;

		@media (max-width: 1240px) {
			padding: 1rem;
			font-size: 2.5rem;
			margin: 3rem 0 2rem;
			width: 65vw;
		}

		@media (max-width: 762px) {
			width: 85vw;
			margin: 0 0 2rem;
		}

		@media (max-width: 560px) {
			width: 85vw;
			margin: 3rem 0 2rem;
		}

		@media (min-width: 1241px) {
			font-size: 3.5rem;
			width: 62vw;
		}

		&::after { display: none; }

		.btn-outline-light:first-child {
			border-radius: 0 .5rem;
		}

		.btn-outline-light:last-child {
			border-radius: .5rem 0;
		}
	}

	.title h2 {
		margin: 0;
		font-size: 1.8rem;
	}

	h2 {
		color: var(--f-d2);
		font-size: 1.5rem;
		font-weight: 300;
		line-height: 1.25;
		margin: .5rem 0 1.5rem;

		@media (max-width: 992px) {
			font-size: 1.75rem;
		}

		@media (max-width: 768px) {
			font-size: 1.5rem;
		}

		&::after { display: none; }
	}

	.buttons {
	//	margin: 0rem 0 12rem auto;
		//margin: 12rem 0 2rem auto;

	padding: 1rem;
	border-radius: .5rem;
	//background: #236e35bb;
	}
}
</style>
