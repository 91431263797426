<template>
  <main class="home">
 
    <campaign v-if="is.before('2025/1/2')" />
  

    <section class="illustration">
      <b-container>
        <b-row>
          <b-col class="d-flex align-items-end justify-content-center">
            <h1
              class="d-none sr-only"
              v-html="$t('meta.title')"
            ></h1>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <div ref="f-sfs">
      <sfs
        :collapsed="false"
      />
    </div>

    <questions />
  </main>
</template>

<script>
 import campaign from './Campaign2024.vue';
import questions from './home/Questions.vue';
import sfs from '../../commons/components/sfs/Main.vue';

export default {
  components: {
    campaign,
    questions,
    sfs,
  },
};
</script>

<style lang="scss">
#home .home .home:not([style*="display: none"]) + .illustration {
  display: none;
}

#home {
  .illustration {
    background: url('../../public/img/home.jpg') top left no-repeat;
    background-size: cover;
    min-height: 30rem;

    @media (min-width: 576px) {
      background-position-x: left -15%;
    }

    @media (min-width: 768px) {
      background-position-x: left -40%;
    }

    @media (min-width: 992px) {
      background-position-x: left 0;
    }
  }

  #f-sfs {
    #support {
      @media (min-width: 992px) { /* cat is hidden by the form below 992px */
        background: url('../../public/img/gattino-galileo.png') no-repeat 10% calc(100% + 40px);
      }

      .col-lg-7 .ombre {
        margin-top: 3rem;
      }

      @media (min-width: 992px) {
        .col-lg-7 .ombre {
          //margin-top: -18rem;
        }
      }
    }
  }

  .illustration[style*="display: none"] + #f-sfs #support .col-lg-7 .ombre {
    margin-top: 3rem; /* when we are in campaign */
  }
}

#home, #defiscalisation {
  #questions {
    background: var(--f-g8) linear-gradient(to bottom right, var(--f-g8), var(--f-g9));

    h1 {
      &::after {
        display: none;
      }
    }

    h2 {
      color: #fff;
      border-bottom: 2px solid;
      border-image: radial-gradient(var(--f-g3), transparent) 1;

      &::after {
        border: none;
      }
    }

    h3 {
      font-size: 1.2rem;

      &::after {
        display: none;
      }
    }

    a {
      background: none;
      color: var(--f-d1);
      text-decoration: underline !important;
    }
  }
}
</style>
