var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"mt-2 d-flex flex-column"},[_c('div',{attrs:{"id":"progress-banner"}},[_c('b-container',{staticClass:"text-center my-5"},[_c('h1',{domProps:{"innerHTML":_vm._s(_vm.$t('c2023.title'))}}),_vm._v(" "),_c('h2',{domProps:{"innerHTML":_vm._s(_vm.$t('c2023.subtitle'))}})]),_vm._v(" "),_c('b-container',{class:_vm.donations.loaded},[_c('div',{class:`banner step-${Math.round(_vm.donations.range / _vm.donations.max * 4)}`,style:(`--pb-range: ${_vm.donations.range}; --pb-max: ${_vm.donations.max};`)},[_c('a',{attrs:{"href":`${_vm.$t('baseurl')}img/campaign/2023/banner${
            Math.round(_vm.donations.range / _vm.donations.max * 2) + 1}-dl.jpg`,"target":"_blank","title":_vm.$t('c2023.download')}},[_c('div',{ref:"banner-active",staticClass:"active"},[_c('div')]),_vm._v(" "),_c('div',{staticClass:"banner-bg"},[_c('div')]),_vm._v(" "),_c('span',{staticClass:"sr-only",domProps:{"innerHTML":_vm._s(_vm.$t('c2023.progressbar', {
              n: Math.round(_vm.donations.range / _vm.donations.max * 2) + 1
            }))}})])]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.donations.range),expression:"donations.range"}],staticClass:"slider",attrs:{"max":_vm.donations.max,"min":"1","type":"range"},domProps:{"value":(_vm.donations.range)},on:{"__r":function($event){return _vm.$set(_vm.donations, "range", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"text-right",style:((_vm.donations.range / _vm.donations.max) < 1
          ? `width: calc(100% * ${_vm.donations.range} / ${_vm.donations.max} + 3rem)`
          : 'width: 100%')},[_c('b-badge',{staticStyle:{"width":"6rem"},attrs:{"variant":"warning"}},[_c('span',{domProps:{"innerHTML":_vm._s(`${_vm.donations.range}&nbsp;€`)}}),_c('br'),_vm._v(" "),_c('small',[_c('i',{domProps:{"innerHTML":_vm._s(`(${Math.min((_vm.donations.range / _vm.donations.max), 1)
                .toLocaleString(_vm.$t('lang'), { style: 'percent' })})`)}})])])],1)]),_vm._v(" "),_c('b-container',{staticClass:"text-center my-5"},[_c('b-row',{attrs:{"align-h":"center"}},[_c('b-col',{attrs:{"lg":"8"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('c2023.desc'))}}),_vm._v(" "),_c('p',{staticClass:"text-center mt-4"},[_c('a',{staticClass:"btn btn-lg btn-warning",attrs:{"href":"#support"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.$parent.$parent.$refs['f-sfs']
                .scrollIntoView({ behavior: 'smooth' })}}},[_c('b',{domProps:{"innerHTML":_vm._s(_vm.$t('c2023.support'))}})])])])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }