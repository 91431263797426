<template>
	<section
			id="c2024-what"
			>
			<b-container class="px-md-0">
			<h2 v-html="$t('c2024.what.title')"></h2>
			<div class="">
				<div class="tabs">
					<input type="radio" name="tabs" id="tab1">
					<label for="tab1">
						<i class="icon icon-1"></i>
						<span v-html="$t('c2024.what.tab1.label')"></span>
					</label>
					<input type="radio" name="tabs" id="tab2">
					<label for="tab2">
						<i class="icon icon-2"></i>
						<span v-html="$t('c2024.what.tab2.label')"></span>
					</label>
					<input type="radio" name="tabs" id="tab3" checked >
					<label for="tab3">
						<i class="icon icon-3"></i>
						<span v-html="$t('c2024.what.tab3.label')"></span>
					</label>
					<input type="radio" name="tabs" id="tab4">
					<label for="tab4">
						<i class="icon icon-4"></i>
						<span v-html="$t('c2024.what.tab4.label')"></span>
					</label>
					<input type="radio" name="tabs" id="tab5">
					<label for="tab5">
						<i class="icon icon-5"></i>
						<span v-html="$t('c2024.what.tab5.label')"></span>
					</label>
					<div id="tab__content--1" class="tab__content">
					  <b-row>
						<b-col class="px-3" md="5" lg="4">
							<div v-html="$t('c2024.what.tab1.desc')"></div>
							<p class="mt-4" v-html="is.after('2024/11/25') ? $t('c2024.what.tab1.more') : $t('c2024.what.more-disabled')"></p>
						</b-col>
						<b-col md="7" lg="8">
						<img class="w-100"
							:src="`${$t('baseurl')}img/campaign/2024/screens/1.jpg`" loading="lazy" alt="" />
						</b-col>
					  </b-row>
					</div>
					<div id="tab__content--2" class="tab__content">
					  <b-row>
						<b-col md="7" lg="8">
						<img class="w-100"
							:src="`${$t('baseurl')}img/campaign/2024/screens/2.jpg`" loading="lazy" alt="" />
						</b-col>
						<b-col class="px-3" md="5" lg="4">
							<div v-html="$t('c2024.what.tab2.desc')"></div>
							<p class="mt-4" v-html="is.after('2024/12/03') ? $t('c2024.what.tab2.more') : $t('c2024.what.more-disabled')"></p>
						</b-col>
					  </b-row>
					</div>
					<div id="tab__content--3" class="tab__content">
					  <b-row>
						<b-col class="px-3" md="5" lg="4">
							<div v-html="$t('c2024.what.tab3.desc')"></div>
							<p class="mt-4" v-html="is.after('2024/12/10') ? $t('c2024.what.tab3.more') : $t('c2024.what.more-disabled')"></p>
						</b-col>
						<b-col md="7" lg="8">
						<img class="w-100"
							:src="`${$t('baseurl')}img/campaign/2024/screens/3.jpg`" loading="lazy" alt="" />
						</b-col>
					  </b-row>
					</div>
					<div id="tab__content--4" class="tab__content">
					  <b-row>
						<b-col md="7" lg="8">
						<img class="w-100"
							:src="`${$t('baseurl')}img/campaign/2024/screens/4.jpg`" loading="lazy" alt="" />
						</b-col>
						<b-col class="px-3" md="5" lg="4">
							<div v-html="$t('c2024.what.tab4.desc')"></div>
							<p class="mt-4" v-html="is.after('2024/12/17') ? $t('c2024.what.tab4.more') : $t('c2024.what.more-disabled')"></p>
						</b-col>
					  </b-row>
					</div>
					<div id="tab__content--5" class="tab__content">
					  <b-row>
						<b-col class="px-3" md="5" lg="4">
							<div v-html="$t('c2024.what.tab5.desc')"></div>
							<p class="mt-4" v-html="is.after('2024/12/24') ? $t('c2024.what.tab5.more') : $t('c2024.what.more-disabled')"></p>
						</b-col>
						<b-col md="7" lg="8">
						<img class="w-100"
							:src="`${$t('baseurl')}img/campaign/2024/screens/5.jpg`" loading="lazy" alt="" />
						</b-col>
					  </b-row>
					</div>
				</div>
				<!--<p
								class="text-center mt-4"
								v-html="$t('c2024.what.more', {
										link: $t('lang') === 'fr'
										? $t('link.fr.convivialisons')
										: $t('link.en.convivialisons')
										})"
										></p>-->
			</div>
			</b-container>
	</section>
</template>

<script>
	export default {
		data() {
			return {
				tabs: {
					count: Object.keys(this.$t('c2024.what')).filter(k => k.includes('tab')).length,
					tabindex: 0,
					animate: true,
				},
			};
		},

		created() {
			const animation = setInterval(() => {
				if (this.tabs.animate) {
					if (this.tabs.tabindex + 1 < this.tabs.count) {
						this.tabs.tabindex += 1;
					} else {
						this.tabs.tabindex = 0;
						this.tabs.animate = false;
					}
				} else {
					clearInterval(animation);
				}
			}, 7500);
		},
	};
</script>

<style lang="scss">
#c2024-what {
	padding: 4rem 0;

	.card-header {
		border: none;
		background: transparent;
		padding: 0;

		.nav {
			background: var(--f-g1);
			border-radius: .25rem;

			li {
				background: transparent;
				padding: 0.75rem 1.25rem;

				@media (max-width: 768px) {
					width: 50%;
				}
			}
		}
	}

	.card-body .row {
		align-items: center;
	}

	h3 {
		font-size: 1.875rem;
		font-weight: 300;
		letter-spacing: -1px;
		line-height: 1;
		margin: 0.5rem 0 1rem;

		@media (max-width: 992px) {
			font-size: 2rem;
		}

		@media (max-width: 768px) {
			font-size: 1.75rem;
		}
	}

	p {
		font-size: 1.35rem;
		font-weight: 200;
		line-height: 1.4;

		@media (max-width: 992px) {
			font-size: 1rem;
		}
	}
	/////

	.tabs {
		list-style: none;
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;
	}
	.tabs:after {
		content: '';
		display: table;
		clear: both;
	}
	.tabs input[type=radio] {
		display:none;
	}
	.tabs label {
		display: inline-block;
		min-width: 15%;
		flex: 1 1 15%;
		text-align: center;
		cursor: pointer;
		-webkit-transition: all 0.5s; /* Safari 3.1 to 6.0 */
		transition: all 0.5s;
	}

	.tabs label span {
		display: none;
		font-size: 1rem;
	}

	.tabs label:hover {
		color: #725794;

		.icon {
			border-color: #725794;
		}
	}

	.tab__content {
		display: none;
		margin: 0 auto;
		box-sizing: border-box;
		margin-top: 2rem;
	}

	.tab__content * {
		-webkit-animation: scale 0.2s ease-in-out;
		-moz-animation: scale 0.2s ease-in-out;
		animation: scale 0.2s ease-in-out;
	}
	@keyframes scale {
		0% {
			transform: scale(0.9);
			opacity: 0;
		}
		50% {
			transform: scale(1.01);
			opacity: 0.5;
		}
		100% {
			transform: scale(1);
			opacity: 1;
		}
	}

	.tabs [id^="tab"]:checked + label {
		color: #725794;
	}

	.tabs [id^="tab"]:checked + label i {
		border-color: #725794;
		background-size: cover;
		background-repeat: no-repeat;
	}

	 #tab1:checked ~ #tab__content--1,
	 #tab2:checked ~ #tab__content--2,
	 #tab3:checked ~ #tab__content--3,
	 #tab4:checked ~ #tab__content--4,
	 #tab5:checked ~ #tab__content--5{
		     display: block;
		 }

@media (min-width: 768px) {
	.tabs label span {
		display: block;
	}
}

.icon {
	margin-top: 1rem;
	display: inline-block;
	width: 150px;
	height: 150px;
	border-radius: 100%;
	border: 4px solid #CACFD6;
	box-sizing: border-box;
	background-size: cover;
	background-repeat: no-repeat;
		@media (max-width: 1200px) {
			width: 120px;
			height: 120px;
		}
		@media (max-width: 992px) {
			width: 90px;
			height: 90px;
			border: 3px solid #CACFD6;
		}
		@media (max-width: 500px) {
			width: 65px;
			height: 65px;
			border: 2px solid #CACFD6;
		}
}

.grid-tabs {
	width: 66vw;
	margin-left: 17vw;
		@media (max-width: 1200px) {
			width: 80vw;
			margin-left: 10vw;
		}
		@media (max-width: 992px) {
			width: 90vw;
			margin-left: 5vw;
		}
		@media (max-width: 500px) {
			width: 95vw;
			margin-left: 2.5vw;
		}
}

.icon-1 {
	background: white url('../../../../public/img/campaign/2024/icons/1.png');
	background-size: cover;
}

.icon-2 {
	background: white url('../../../../public/img/campaign/2024/icons/2.png');
	background-size: cover;
}

.icon-3 {
	background: white url('../../../../public/img/campaign/2024/icons/3.png');
	background-size: cover;
}

.icon-4 {
	background: white url('../../../../public/img/campaign/2024/icons/4.png');
	background-size: cover;
}

.icon-5 {
	background: white url('../../../../public/img/campaign/2024/icons/5.png');
	background-size: cover;
}

}
</style>
