<template>
  <main>
    <section
      id="mecenes"
      class="py-4"
    >
      <b-container>
        <h1 class="text-center mt-4 mb-5">
          Mécènes
        </h1>
        <b-row
          align-h="center"
          class="mb-5"
        >
          <b-col
            lg="8"
            xl="9"
          >
            <b-card class="fb-m2">
              <p>
                Pour devenir mécène, les paliers de dons sont les suivants&nbsp;:
              </p>
              <ul class="list-unstyled">
                <li>
                  <span class="badge badge-secondary">Palier 1</span>
                  Entre 200&nbsp;€ et 499&nbsp;€&nbsp;: affichage pendant
                  un an dans la <b>liste alphabétique</b>;
                </li>
                <li>
                  <span class="badge badge-secondary">Palier 2</span>
                  À partir de 500&nbsp;€&nbsp;: affichage pendant un an
                  en <b>tête de liste</b> de la page.
                </li>
              </ul>
              <p>
                Pour ajouter votre structure à la liste,
                <b>merci de</b>
                <a :href="`${$t('link.contact')}/#support`">
                  <b>nous transmettre</b>
                </a>
                <i><small>(bouton «&nbsp;Contacter le support de Framasoft&nbsp;»)</small></i>
                <b>les informations suivantes</b>&nbsp;:
              </p>
              <ul>
                <li>
                  le montant de <b>votre don</b>
                </li>
                <li>
                  votre <b>logo</b>
                </li>
                <li>
                  le lien de votre <b>site officiel</b>
                </li>
                <li>
                  une <b>description</b> de votre structure
                  <i><small>
                    (nous nous réservons le droit de reformuler si elle est
                    trop longue)
                  </small></i>
                  <br />
                  <span class="badge badge-secondary">Palier 1</span> &lt; 600 caractères&nbsp;
                  <br />
                  <span class="badge badge-secondary">Palier 2</span> &lt; 2 500 caractères
                </li>
              </ul>
              <p>
                Pour procéder au don, il faut utiliser
                <a href="/">le même formulaire que celui destiné aux particuliers</a>
                en précisant que vous représentez «&nbsp;une structure&nbsp;» dans le champ prévu.
              </p>
              <p>
                Une fois le paiement validé nous vous enverrons le reçu fiscal qui
                servira de pièce comptable et qui vous permettra (en France uniquement)
                de déduire 60% du don de votre impôt sur les sociétés.
              </p>
            </b-card>
          </b-col>
        </b-row>
        <partner
          v-for="partner in Object.keys(this.$t('partners'))"
          :key="partner"
          :partner="partner"
        />
      </b-container>
    </section>
  </main>
</template>

<script>
import Partner from '../components/Partner.vue';

export default {
  components: {
    Partner,
  },
};
</script>

<style lang="scss">
#sponsors {
  main img {
    object-fit: scale-down;
    background: #fff;
    border-radius: 100px;
    width: 200px;
    height: 200px;

    &[src$='aft-rn.jpg'] { background: #efc100; }
    &[src$='picardie.png'] { background: #8bbc00; }
    &[src$='albatros.jpg'] { background: #0f2439; }
  }

  h2 {
    &::after {
      display: none;
    }
  }

  #mecenes {

    h1 {
      color: var(--f-o9);
      &::after {
        border: 3px solid var(--f-o9);
      }
    }

    h2 a {
      color: var(--f-g8);
    }

    img { border: 5px solid var(--f-g3); }

    .row:not(:first-of-type) {
      border-top: 1px solid;
      border-image: radial-gradient(var(--f-g7), transparent) 1;
    }

    .alpha {
      background: var(--f-g2);
    }

    .feb-v2 {
      background: var(--f-o2);

      h2 a {
        color: var(--f-o8);
      }

      img { border: 5px solid var(--f-o3); }

      &.row {
        border-top: 1px solid;
        border-image: radial-gradient(var(--f-o7), transparent) 1;
      }
    }
  }
}

small.date-ajout {
  display: none; // Don't show when they were added for now
}
</style>
