<template>
  <header id="header">
    <b-container class="py-1">
      <b-row
        align-h="between"
        align-v="center"
      >
        <b-col
          class="text-center text-md-left px-0"
          lg="5"
        >
          <h1 class="d-inline-block">
            <router-link
              :to="`/${$t('lang')}/`"
              class="align-items-center justify-content-center justify-content-lg-start"
            >
              <img
                alt=""
                :src="`${$t('baseurl')}img/icons/soutenir.svg`"
              />
              <span
                class="h3 m-0 pt-1 pl-1"
                v-html="$t('meta.framaname')"
              ></span>
            </router-link>
          </h1>
        </b-col>
        <b-col
          class="px-0"
          lg="7"
        >
          <Menu />
        </b-col>
      </b-row>
    </b-container>
  </header>
</template>

<script>
import Menu from './Menu.vue';

export default {
  components: {
    Menu,
  },
};
</script>

<style lang="scss">
#header {
  background: #fff;
  border-bottom: 1px solid var(--f-g4);

  h1 {
    margin: 0;

    a {
      text-decoration: none;
      display: flex;

      img {
        width: 64px;
        height: 64px;
        padding: .25rem .25rem .25rem 0;
      }

      .h3 {
        color: var(--f-g7);
      }
    }
  }

  .navbar-light .navbar-nav {
    .nav-item {
      display: flex;
    }

    .nav-link {
      align-self: center;
    }
  }

  .nav-item:not(:last-of-type) .nav-link {
    text-decoration: none;
    color: var(--f-g7) !important;

    background-image:
      linear-gradient(var(--f-g7), var(--f-g7));
    background-size: 0% 4px;
    background-position: center bottom;
    background-repeat: no-repeat;
    transition: background-size 0.25s ease-in-out;

    &:hover, &:focus {
      background-size: calc(100% - 1rem) 4px;
    }
  }

  .dropdown {
    button {
      border: none;
      color: var(--f-g7);
      fill: var(--f-g7);

      &:hover {
        color: var(--f-g1);
        fill: var(--f-g2);
      }
    }

    &.show button{
      color: var(--f-g1);
      fill: var(--f-g2);
    }

    li a {
      background: transparent;

      &:hover, &focus {
        background-color:  var(--f-g2);
      }
    }
  }
}
</style>
