<template>
  <div :class="`fb-${label === 'graphAll' ? 'g' : 'm'}1 p-2 rounded mb-4 border`">
    <la-cartesian
      autoresize
      :colors="[
        label === 'graphAll' ? '#e2e8f0' : '#f0e5e2',
        '#31acce',
        '#c47123',
        '#725794',
        '#cc4e13',
      ]"
      text-color="#2d3748"
      :bound="[0, n => n + bound]"
      :data="data"
    >
      <la-area
        :label="$t(`stats.${label}.all`)"
        :continued="false"
        curve
        prop="all"
      />
      <la-line
        :label="$t(`stats.${label}.onetime`)"
        :continued="false"
        :width="2"
        :dashed="data.length < 300"
        :dot="data.length < 300"
        curve
        prop="onetime"
      />
      <la-line
        v-if="donators.monthly > 0"
        :label="$t(`stats.${label}.monthly`)"
        :continued="false"
        :width="2"
        :dashed="data.length < 300"
        :dot="data.length < 300"
        curve
        prop="monthly"
      />
      <la-line
        v-if="donators.peertube > 0"
        :label="$t(`stats.${label}.peertube`)"
        :continued="false"
        :width="2"
        :dashed="data.length < 300"
        :dot="data.length < 300"
        curve
        prop="peertube"
      />
      <la-line
        v-if="donators.mobilizon > 0"
        :label="$t(`stats.${label}.mobilizon`)"
        :continued="false"
        :width="2"
        :dashed="data.length < 300"
        :dot="data.length < 300"
        curve
        prop="mobilizon"
      />
      <la-y-axis />
      <la-x-axis prop="name" />
      <la-tooltip />
      <la-legend selectable />
    </la-cartesian>
  </div>
</template>

<script>
import {
  Cartesian, Area, Line, XAxis, YAxis, Tooltip, Legend,
} from 'laue';

export default {
  components: {
    LaCartesian: Cartesian,
    LaArea: Area,
    LaLine: Line,
    LaXAxis: XAxis,
    LaYAxis: YAxis,
    LaTooltip: Tooltip,
    LaLegend: Legend,
  },

  props: {
    data: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    bound: {
      type: Number,
      required: true,
    },
    donators: {
      type: Object,
      required: true,
    },
  },
};
</script>
