<template>
  <main class="home position-relative">
    <intro />
	<twosteps />

    <section class="quote text-left pt-4">
      <b-container class="mb-0">
        <b-row>
          <b-col id="h-tube" md="6">
			  <div>
                <h3 v-html="$t('c2024.why.tube.label')"></h3>
                  <div class="p-2 mx-n2">
				  <b-row>
					<b-col class="px-3" md="8">
					<div class="mt-4" v-html="is.after('2024/11/25') ? $t('c2024.why.tube.title') : $t('c2024.why.tube.ctitle')"></div>
					</b-col>
					<b-col class="px-3" lg="4">
                    <div class="img-thumbnail why-tube-img text-center position-relative mt-4">
						<a href="https://www.projets-libres.org/framasoft-20-ans-episode-1-10-premieres-annees/">
              <span class="sr-only" v-html="$t('c2024.why.tube.title')" />
                      <img
                        alt=""
                        class="img-fluid"
						:src="$t(`${$t('baseurl')}img/campaign/2024/projets-libre.png`)"
                      />
						</a>
					</div>
					</b-col>
				  </b-row>
                  </div>
			  </div>

          </b-col>
          <b-col id="h-tube" md="6">
			  <div>
                <h3 v-html="$t('c2024.why.web.label')"></h3>
                  <div class="p-2 mx-n2">
				  <b-row>
					<b-col class="px-3" md="8">
                    <div v-html="$t('c2024.why.web.title')"></div>
					</b-col>
					<b-col class="px-3" lg="4">
                    <div class="img-thumbnail why-web-img text-center position-relative mt-4">
						<a href="https://20ans.framasoft.org">
              <span class="sr-only" v-html="$t('c2024.why.web.title')" />
                      <img
                        alt=""
                        class="img-fluid"
						:src="$t(`${$t('baseurl')}img/campaign/2024/site-20-ans.png`)"
						/>
						</a>
					</div>
					</b-col>
				  </b-row>
                  </div>
			  </div>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <what />

	<!--<how />-->

    <section class="quote pt-4">
      <b-container class="mb-0">
        <b-row>
          <b-col>
            <blockquote class="blockquote text-right">
              <span v-html="$t('c2024.why.list[0]')"></span>
              <footer class="blockquote-footer">
                <a
                  :href="$t('c2024.why.link')"
                  v-html="$t('c2024.why.blog')"
                ></a>
              </footer>
            </blockquote>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </main>
</template>

<script>
import intro from './campaign/2024/Intro.vue';
import what from './campaign/2024/What.vue';
import how from './campaign/2024/How.vue';
import twosteps from './campaign/2024/Twosteps.vue';

export default {
  components: {
    intro,
    what,
    how,
	twosteps,
  },

  created() {
    /* Quack quack here, quack quack there… */
    if (/quack/.test(window.location.search)) {
      document.body.classList.add('quack');
    }
  },
};
</script>

<style lang="scss">
#c2024-what, #c2024-how, #c2024-frama, #progress-banner {
  h2, .h2 {
    color: var(--f-g10);
    font-family: 'Tovari Sans';
    font-size: 3rem;
    line-height: 2.5rem;
    margin-bottom: 1rem;
  }
}

#support, #lastyear {
  h2 {
    background: var(--f-o6);
    border-radius: .5rem;
    color: #fff;
    font-family: 'Tovari Sans';
    font-size: 3rem;
    font-style: normal;
    line-height: 2.5rem;
    padding: 1.25rem 1.75rem .5rem;
    text-align: center;
    text-transform: uppercase;
    transform: rotate(-2deg);
    width: fit-content;

    margin: 0 auto 2rem 0;
    /* @media (min-width: 992px) {
      margin: -7rem auto 2rem 0;
    } */
  }

  h3 {
    color: var(--f-g10);
  }
}

#actions {
  h1 {
    background: var(--f-o6);
    border-radius: .5rem;
    color: #fff;
    font-family: 'Tovari Sans';
    font-size: 3rem;
    font-style: normal;
    line-height: 2.5rem;
    padding: 1.25rem 1.75rem .5rem;
    text-align: center;
    text-transform: uppercase;
    transform: rotate(-2deg);
    width: fit-content;
    margin: -5rem auto 3rem 0 !important;
  }

  h2 {
    font-family: 'Tovari Sans';
    font-size: 3rem;
    line-height: 2.5rem;
    margin-bottom: 1rem;
    text-transform: none;
    transform: none;
  }
}

#lastyear {
  h2 {
    margin: -5rem auto 4rem 0;
  }

  h3 {
    color: var(--f-g10);
    font-family: 'Tovari Sans';
    font-size: 3rem;
    line-height: 2.5rem;
    margin-bottom: 1rem;
  }
}

#questions {
  h2 {
    font-family: 'Tovari Sans';
    font-size: 3rem;
    line-height: 2.5rem;
    margin-bottom: 1rem;
  }
}

/* Quote */
.quote {
  background: var(--f-g4);

  p {
    padding-top: 10px;
    text-align: left;
  }

  a {
    color: var(--f-g8);
    text-decoration: none;
    background: none !important;

    &:hover, &:focus {
      text-decoration: underline !important;
    }
  }

  text-align: center;

  button.btn {
    font-size: 1.3em;
    width: 14em;
    word-break: break-word;
    white-space: normal;

    &::first-line {
      font-size: 1.5em;
    }
  }

  blockquote {
    padding: 0;
    max-width: 850px;
    margin-left: auto;
    background: transparent;
    border: none;

    &::before { display: none; }

    span {
      border-right: 0.2em solid var(--f-g8);
      position: relative;
      display: block;
      padding-right: 1em;
    }
  }
  .why-tube-img{
//	margin: 0 32% 0 25%;
  }
  .why-web-img{
//	margin: 0 14% 0 14%;
  }
}

/* Quack quack here… */
.quack .quack[style] {
  display: block !important;
}

.quack .quack + * {
  display: none;
}

.quack #c2024-intro {
  &::after {
    background-image: url('../../public/img/campaign/2022/coin-coin.jpg');
    background-position: 100%;
  }

  .buttons {
    background: #4787aabb;

    margin: 12rem 0 2rem;
    @media (min-width: 992px) {
      margin: 9rem 0 6rem;
    }
  }
}

#h-web,
#h-tube {
  margin: 3.5rem auto;

  > div {
    background: var(--f-g2);
    border-radius: 1.75rem;
    padding: .5rem 1.5rem;
    position: relative;
    z-index: 1;
    height: 100%;

    a {
      background-image: none;
      color: var(--f-g9);
      font-weight: bold;

      p {
        margin-bottom: .5rem;
      }

      p:last-of-type {
        line-height: 1.5;
      }
    }
  }

  h3 {
    background: var(--f-o6);
    border-radius: .5rem;
    color: #fff;
    font-family: 'Tovari Sans', sans-serif;
    font-size: 2rem;
    margin: -1.5rem auto 0.5rem 0;
    padding: .25rem .75rem 0rem;
    text-align: center;
    text-transform: uppercase;
    transform: rotate(-2deg);
    width: fit-content;
  }

  .small {
    color: var(--f-g7) !important;
  }
}

#h-tube img {
  + img {
    position: absolute;
    top: calc(50% - 25%);
    left: calc(50% - 12.5%);
    width: 25%;
  }
}
</style>
