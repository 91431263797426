import Vue from 'vue';
import VueRouter from 'vue-router';
import { direct as cookie } from '../commons/plugins/cookie';

Vue.use(VueRouter);

if (window.location.search) {
  cookie('w', 'f-track', window.location.search);
}

const config = process.env.CONFIG;
const routes = [];
const userLang = navigator.languages
  || [navigator.language || navigator.userLanguage];
let defaultRouteLang = '';

for (let i = 0; i < config.translations.available.length; i += 1) {
  const k = config.translations.available[i];
  // Localized routes
  for (let j = 0; j < config.views.length; j += 1) {
    const file = config.views[j];
    const endpoint = file.toLowerCase();
    const component = require(`./views/${file}.vue`); // eslint-disable-line
    routes.push({
      path: `/${k}${endpoint.replace(/^/, '/').replace('/home', '')}`,
      component: component.default,
      meta: { id: endpoint, lang: k },
    });
  }
}

// define defaultRouteLang
for (let j = 0; j < userLang.length; j += 1) { // check if user locales
  const lg = userLang[j].substring(0, 2).toLowerCase();
  if (defaultRouteLang === '' && Object.keys(config.translations).includes(lg)) { // matches with app locales
    defaultRouteLang = lg;
  }
}

// Redirections
for (let i = 0; i < config.views.length; i += 1) {
  if (!window.vuefsPrerender) {
    routes.push({
      path: `/${config.views[i].toLowerCase().replace('home', '')}`,
      redirect: `/${defaultRouteLang}/${config.views[i].toLowerCase().replace('home', '')}`,
    });
  } else {
    // Component needed for SEO
    const file = config.views[i];
    const endpoint = file.toLowerCase();
    const component = require(`./views/${file}.vue`); // eslint-disable-line
    routes.push({
      path: `/${endpoint.replace('home', '')}`,
      component: component.default,
    });
  }
}

// Routes
const router = new VueRouter({
  base: `${__dirname}${config.base}`.replace('//', '/'),
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        offset: { x: 0, y: 60 },
      };
    }
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

export default router;
