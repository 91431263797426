<template>
  <div>
    <section
      id="c2022-intro"
      class="main-row"
    >
      <b-container class="px-md-0">
        <b-row class="m-0">
          <b-col
            class="text-center p-0 quack"
            style="display :none;"
            md="6"
            lg="4"
          >
            <h1>
              <span v-html="$t('c2022.intro.ctitle')"></span>
              <div class="d-flex justify-content-between mx-n3 mb-n3 mt-n4">
                <b-btn
                  aria-hidden="true"
                  href="?"
                  variant="outline-light border-0"
                >
                  <span v-html="'🥚'"></span>
                </b-btn>
                <b-btn
                  aria-hidden="true"
                  href="?"
                  variant="outline-light border-0"
                >
                  <span v-html="'🦆'"></span>
                </b-btn>
              </div>
            </h1>
          </b-col>
          <b-col
            class="text-center p-0"
            lg="11"
            xl="10"
          >
            <h1>
              <span v-html="$t('c2022.intro.title')"></span>
              <div class="d-flex justify-content-between mx-n3 mb-n3 mt-n4">
                <b-btn
                  aria-hidden="true"
                  href="?quack"
                  variant="outline-light border-0"
                >
                  <span v-html="'🥚'"></span>
                </b-btn>
                <b-btn
                  aria-hidden="true"
                  href="?quack"
                  variant="outline-light border-0"
                >
                  <span v-html="'🦆'"></span>
                </b-btn>
              </div>
            </h1>
          </b-col>
        </b-row>
        <b-row class="m-0">
          <b-col
            class="buttons"
            md="6"
            lg="4"
          >
            <h2
              class="quack"
              style="display :none;"
              v-html="$t('c2022.intro.csubtitle')"
            ></h2>
            <h2 v-html="$t('c2022.intro.subtitle')"></h2>
            <b-row
              class="quack"
              style="display: none;"
            >
              <b-col cols="12">
                <a
                  class="btn btn-lg btn-soutenir w-100 h-100 my-2
                  d-flex align-items-center justify-content-center"
                  href="#support"
                >
                  <span>
                    <i class="fa fa-heart mr-2"></i>
                    <span v-html="$t('c2022.intro.csupport')"></span>
                  </span>
                </a>
              </b-col>
              <b-col cols="12">
                <a
                  class="btn btn-lg btn-primary w-100 h-100 my-2
                  d-flex align-items-center justify-content-center"
                  href="#c2022-what"
                >
                  <span>
                    <i class="fa fa-route mr-2"></i>
                    <span v-html="$t('c2022.intro.croadmap')"></span>
                  </span>
                </a>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <a
                  class="btn btn-lg btn-soutenir w-100 h-100
                  d-flex align-items-center justify-content-center"
                  href="#support"
                >
                  <span>
                    <i class="fa fa-heart"></i>
                    <span v-html="$t('c2022.intro.support')"></span>
                  </span>
                </a>
              </b-col>
              <b-col cols="6">
                <a
                  class="btn btn-lg btn-primary w-100 h-100
                  d-flex align-items-center justify-content-center"
                  href="#c2022-what"
                >
                  <span>
                    <i class="fa fa-route"></i>
                    <span v-html="$t('c2022.intro.roadmap')"></span>
                  </span>
                </a>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
export default {

  data() {
    return {
      c: '',
    };
  },
};
</script>

<style lang="scss">
#c2022-intro {
  filter: drop-shadow(2px 5px 4px rgba(0, 0, 0, 0.2));
  padding: 1rem 0 3rem;
  position: relative;

  @media (max-width: 480px) {
    max-height: 600px;
  }

  &::before,
  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &::before {
    background: var(--f-f7);
    -webkit-clip-path: polygon(0 0, 0 100%, 100% 83%, 100% 0);
    clip-path: polygon(0 0, 0 100%, 100% 83%, 100% 0);

    @media (max-width: 768px) {
      -webkit-clip-path: polygon(0 0, 0 100%, 100% 93%, 100% 0);
      clip-path: polygon(0 0, 0 100%, 100% 93%, 100% 0);
    }
  }

  &::after {
    background: #daa76e;
    background-image: url('../../../../public/img/campaign/2022/intro.jpg');
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 88%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 88%);

    @media (max-width: 768px) {
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 98%, 0 95%);
      clip-path: polygon(0 0, 100% 0, 100% 98%, 0 95%);
    }
  }

  .container {
    position: relative;
    z-index: 1;
  }

  h1 {
    color: var(--f-d2);
    background: #dd6418cc;
    font-family: 'Tovari Sans';
    font-weight: bold;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 2rem;
    margin: 2rem 0 1.5rem;
    padding: 1rem;
    border-radius: .5rem;
    line-height: 1;

    @media (min-width: 768px) {
      font-size: 2.5rem;
      margin: 3rem 0 2rem;
    }

    @media (min-width: 992px) {
      font-size: 3.5rem;
      margin: 3rem 0 4rem;
    }

    @media (min-width: 1200px) {
      font-size: 4rem;
    }

    &::after { display: none; }

    .btn-outline-light:first-child {
      border-radius: 0 .5rem;
    }

    .btn-outline-light:last-child {
      border-radius: .5rem 0;
    }
  }

  h2 {
    color: var(--f-d2);
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.25;
    margin: .5rem 0 1.5rem;

    @media (max-width: 992px) {
      font-size: 1.75rem;
    }

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }

    &::after { display: none; }
  }

 .buttons {
    margin: 9rem 0 8rem;
    margin: 12rem 0 2rem;
    @media (min-width: 992px) {
      margin: 9rem 0 6rem;
    }
    padding: 1rem;
    border-radius: .5rem;
    background: #236e35bb;
  }
}
</style>
