var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`fb-${_vm.label === 'graphAll' ? 'g' : 'm'}1 p-2 rounded mb-4 border`},[_c('la-cartesian',{attrs:{"autoresize":"","colors":[
      _vm.label === 'graphAll' ? '#e2e8f0' : '#f0e5e2',
      '#31acce',
      '#c47123',
      '#725794',
      '#cc4e13',
    ],"text-color":"#2d3748","bound":[0, n => n + _vm.bound],"data":_vm.data}},[_c('la-area',{attrs:{"label":_vm.$t(`stats.${_vm.label}.all`),"continued":false,"curve":"","prop":"all"}}),_vm._v(" "),_c('la-line',{attrs:{"label":_vm.$t(`stats.${_vm.label}.onetime`),"continued":false,"width":2,"dashed":_vm.data.length < 300,"dot":_vm.data.length < 300,"curve":"","prop":"onetime"}}),_vm._v(" "),(_vm.donators.monthly > 0)?_c('la-line',{attrs:{"label":_vm.$t(`stats.${_vm.label}.monthly`),"continued":false,"width":2,"dashed":_vm.data.length < 300,"dot":_vm.data.length < 300,"curve":"","prop":"monthly"}}):_vm._e(),_vm._v(" "),(_vm.donators.peertube > 0)?_c('la-line',{attrs:{"label":_vm.$t(`stats.${_vm.label}.peertube`),"continued":false,"width":2,"dashed":_vm.data.length < 300,"dot":_vm.data.length < 300,"curve":"","prop":"peertube"}}):_vm._e(),_vm._v(" "),(_vm.donators.mobilizon > 0)?_c('la-line',{attrs:{"label":_vm.$t(`stats.${_vm.label}.mobilizon`),"continued":false,"width":2,"dashed":_vm.data.length < 300,"dot":_vm.data.length < 300,"curve":"","prop":"mobilizon"}}):_vm._e(),_vm._v(" "),_c('la-y-axis'),_vm._v(" "),_c('la-x-axis',{attrs:{"prop":"name"}}),_vm._v(" "),_c('la-tooltip'),_vm._v(" "),_c('la-legend',{attrs:{"selectable":""}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }