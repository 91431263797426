<template>
  <section
    id="c2024-twosteps"
  >
    <b-container class="px-md-0">
      <b-row>
        <b-col>
          <h2 v-html="$t('c2024.twosteps.title')"></h2>
          <b-tabs
            v-model="tabs.tabindex"
            nav-class="my-4 mx-0"
            card
            fill
            pills
          >
            <b-tab
              active
              class="p-0"
              :title="$t('c2024.twosteps.tab1.label')"
              @click="tabs.animate = false"
            >
              <b-row>
                <b-col
                  class="px-3"
                  md="5"
                  lg="4"
                >
                  <div v-html="$t('c2024.twosteps.tab1.desc')"></div>
                </b-col>
                <b-col
                  md="7"
                  lg="8"
                >
                  <img
                    alt=""
                    class="w-100 rounded"
                    :src="`${$t('baseurl')}img/campaign/2024/screens/6.jpg`"
                  />
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              class="p-0"
              :title="$t('c2024.twosteps.tab2.label')"
              @click="tabs.animate = false"
            >
              <b-row>
                <b-col
                  class="px-3"
                  md="6"
                  lg="8"
                >
                  <img
                    alt=""
                    class="w-100 rounded"
                    :src="`${$t('baseurl')}img/campaign/2024/screens/7.jpg`"
                  />
                </b-col>
                <b-col
                  md="6"
                  lg="4"
                  order-md="2"
                >
                  <div v-html="$t('c2024.twosteps.tab2.desc')"></div>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
          <p
            class="text-center mt-4"
            v-html="$t('c2024.twosteps.more', {
              link: $t('lang') === 'fr'
                ? $t('link.fr.convivialisons')
                : $t('link.en.convivialisons')
            })"
          ></p>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  data() {
    return {
      tabs: {
        count: Object.keys(this.$t('c2024.twosteps')).filter(k => k.includes('tab')).length,
        tabindex: 0,
        animate: true,
      },
    };
  },

  created() {
    const animation = setInterval(() => {
      if (this.tabs.animate) {
        if (this.tabs.tabindex + 1 < this.tabs.count) {
          this.tabs.tabindex += 1;
        } else {
          this.tabs.tabindex = 0;
          this.tabs.animate = false;
        }
      } else {
        clearInterval(animation);
      }
    }, 7500);
  },
};
</script>

<style lang="scss">
#c2024-twosteps {
  padding: 4rem 0;

  .card-header {
    border: none;
    background: transparent;
    padding: 0;

    .nav {
      background: var(--f-g1);
      border-radius: .25rem;

      li {
        background: transparent;
        padding: 0.75rem 1.25rem;

        @media (max-width: 768px) {
          width: 50%;
        }
      }
    }
  }

  .card-body .row {
    align-items: center;
  }

  h3 {
    font-size: 1.875rem;
    font-weight: 300;
    letter-spacing: -1px;
    line-height: 1;
    margin: 0.5rem 0 1rem;

    @media (max-width: 992px) {
      font-size: 2rem;
    }

    @media (max-width: 768px) {
      font-size: 1.75rem;
    }
  }

  p {
    font-size: 1.35rem;
    font-weight: 200;
    line-height: 1.4;

    @media (max-width: 992px) {
      font-size: 1rem;
    }
  }
}
</style>
