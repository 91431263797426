<template>
  <section class="mt-2 d-flex flex-column">
    <div id="progress-banner">
      <b-container class="text-center my-5">
        <h1
          v-html="$t('c2023.title')"
        ></h1>
        <h2
          v-html="$t('c2023.subtitle')"
        ></h2>
      </b-container>

      <b-container :class="donations.loaded">
        <div
          :class="`banner step-${Math.round(donations.range / donations.max * 4)}`"
          :style="`--pb-range: ${donations.range}; --pb-max: ${donations.max};`"
        >
          <a
            :href="`${$t('baseurl')}img/campaign/2023/banner${
              Math.round(donations.range / donations.max * 2) + 1}-dl.jpg`"
            target="_blank"
            :title="$t('c2023.download')"
          >
            <div
              ref="banner-active"
              class="active"
            >
              <div></div>
            </div>
            <div class="banner-bg">
              <div></div>
            </div>
            <span
              class="sr-only"
              v-html="$t('c2023.progressbar', {
                n: Math.round(donations.range / donations.max * 2) + 1
              })"
            ></span>
          </a>
        </div>
        <input
          v-model="donations.range"
          class="slider"
          :max="donations.max"
          min="1"
          type="range"
        />
        <div
          :style="(donations.range / donations.max) < 1
            ? `width: calc(100% * ${donations.range} / ${donations.max} + 3rem)`
            : 'width: 100%'"
          class="text-right"
        >
          <b-badge
            style="width: 6rem"
            variant="warning"
          >
            <span v-html="`${donations.range}&nbsp;€`"></span><br />
            <small>
              <i
                v-html="`(${Math.min((donations.range / donations.max), 1)
                  .toLocaleString($t('lang'), { style: 'percent' })})`"
              ></i>
            </small>
          </b-badge>
        </div>
      </b-container>

      <b-container class="text-center my-5">
        <b-row align-h="center">
          <b-col lg="8">
            <div v-html="$t('c2023.desc')"></div>
            <p class="text-center mt-4">
              <a
                class="btn btn-lg btn-warning"
                href="#support"
                @click.prevent.stop="$parent.$parent.$refs['f-sfs']
                  .scrollIntoView({ behavior: 'smooth' })"
              >
                <b v-html="$t('c2023.support')"></b>
              </a>
            </p>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      donations: {
        current: 0, // real stats (from 2023.json)
        loaded: '',
        init: 198968, // at the beginning (2023/11/13)
        range: 20000, /* = current - init when json loaded
                    users can play with the slider to colourize the banner
                    so the value is reset periodically (cf created()) */
        max: 200000, // campaign target
      },
    };
  },

  created() {
    document.body.classList.add('campaign');

    /* Reinit banner progressbar */
    setInterval(() => {
      const range = Math.max(this.donations.current - this.donations.init, 0);
      if (this.donations.range !== range) {
        this.donations.range = range;
      }
    }, 5000);

    fetch('https://soutenir.framasoft.org/dons/2023.json', { cache: 'no-cache' })
      .then(res => res.json())
      .then((data) => {
        for (let i = 0; i < data.list.length; i += 1) {
          this.donations.current += Math.round(data.list[i].don);
        }
        this.donations.range = Math.max(this.donations.current - this.donations.init, 0);
        this.donations.loaded = 'loaded';
      })
      .catch((err) => { console.error(err) }); // eslint-disable-line
  },

  methods: {},
};
</script>

<style lang="scss">
#home.campaign,
#campaign2023.campaign {
  #f-sfs {
    background: var(--f-g3) !important;

    #support {
      .col-lg-7 .ombre {
        margin-top: 3rem !important;
      }
    }
  }

  .sticky-top .btn-outline-secondary {
    background-color: var(--f-g2);
    border-color: transparent;

    &:hover, &:focus {
      background: var(--f-g7);
    }
  }
}

#progress-banner {
  display: block;
  order: 6;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-top: 1rem;

  h1 {
    color: #fff;
    background: var(--f-o6);
    font-family: 'Tovari Sans';
    font-weight: bold;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 3rem;
    margin: 2rem auto 1.5rem;
    padding: 1rem 1rem 0;
    border-radius: .5rem;
    line-height: 1;
    max-width: 600px;

    @media (min-width: 768px) {
      font-size: 3.5rem;
      margin: 3rem auto 4rem;
    }

    @media (min-width: 1200px) {
      font-size: 4rem;
    }

    &::after { display: none; }

    .btn-outline-light:first-child {
      border-radius: 0 .5rem;
    }

    .btn-outline-light:last-child {
      border-radius: .5rem 0;
    }
  }

  h2 {
    color: var(--f-d2);
    font-size: 2.5rem !important;
    font-weight: 300;
    line-height: 1.25;
    margin: .5rem 0 1.5rem;

    @media (min-width: 768px) {
      font-size: 3rem !important;
    }

    &::after { display: none; }
  }

  .banner {
    border: none;
    border-top: none;
    border-left: none;
    border-right: none;
    position: relative;
    height: 360px;
    width: 100%;

    @media (min-width: 768px) {
      height: 520px;
    }
  }

  .active, .banner-bg {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    div {
      --pb-w: calc(100vw - 45px); // container-fluid || mobile
      @media (min-width: 576px) {
        --pb-w: 510px;
      }
      @media (min-width: 768px) {
        --pb-w: 690px;
      }
      @media (min-width: 992px) {
        --pb-w: 930px;
      }
      @media (min-width: 1200px) {
        --pb-w: 1110px;
      }

      width: var(--pb-w);
      height: 100%;
      background-image: none;
      background-size: cover;
      background-position: 1rem center;
      background-position-x:
        calc((var(--pb-w) * var(--pb-range) / var(--pb-max)));
      &::after {
        border-bottom: .5rem solid transparent;
        content: '';
        display: block;
        width: 100%;
      }
    }
  }

  .banner-bg div {
    background-image: url('../../../../public/img/campaign/2023/banner1.jpg');
  }

  .step-1 {
    .banner-bg {
      div {
        background-image: url('../../../../public/img/campaign/2023/banner2.jpg');
      }
    }
  }

  .step-2, .step-3, .step-4 {
    .banner-bg {
      div {
        background-image: url('../../../../public/img/campaign/2023/banner3.jpg');
      }
    }
  }

  .active {
    width: calc(100% * var(--pb-range) / var(--pb-max));
    div {
      border-bottom: 1rem solid var(--f-o5);
    }
  }

  .active {
    // border-right: .5rem solid var(--f-o7);
    border-right: none;
    box-shadow: none;
    transition: width .1s linear;
    z-index: 1;
  }

  .animate {
    transition: width 1s ease-in-out;
  }

  .slider {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    display: block;
    margin-left: -12px;
    margin-right: -12px;
    margin-top: 3px;
    outline: none;
    width: calc(100% + 24px);
    z-index: 2;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      background: transparent;
      border: 12px solid var(--f-o7);
      border-top: 0 solid transparent;
      border-left-color: transparent;
      border-right-color: transparent;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    &::-moz-range-thumb {
      background: transparent;
      border: 12px solid var(--f-o7);
      border-top: 0 solid transparent;
      border-left-color: transparent;
      border-right-color: transparent;
      cursor: pointer;
      height: 0;
      width: 0;
    }
  }

  .badge {
    padding: .5rem;
    span { font-size: larger; }
  }

  .slider, .badge {
    visibility: hidden;
  }

  .loaded {
    .slider, .badge {
      visibility: visible;
    }
  }

  .text-center.container {
    font-size: larger;
  }
}
</style>
