<template>
  <b-navbar
    class="align-items-center justify-content-center justify-content-lg-end px-0"
  >
    <b-navbar-nav>
      <b-nav-item-dropdown right>
        <template v-slot:button-content>
          <i class="fas fa-bulding-columns fa-lg fa-fw d-lg-none"></i>
          <span
            class="sr-only"
            v-html="$t('menu[0]')"
          ></span>
          <span
            class="d-none d-lg-inline text-nowrap"
            v-html="$t('menu[0]')"
          ></span>
        </template>
        <b-dropdown-item :href="$t('link.asso')">
          <span v-html="$t('menu[0]')"></span>
        </b-dropdown-item>

        <b-dropdown-item :to="`/${$t('lang')}/benevalo`">
          <span v-html="$t('menu[1]')"></span>
        </b-dropdown-item>

        <b-dropdown-item :to="`/${$t('lang')}/banners`">
          <span v-html="$t('menu[2]')"></span>
        </b-dropdown-item>
      </b-nav-item-dropdown>

      <b-nav-item :to="`/${$t('lang')}/guestbook`">
        <i class="fas fa-quote-left fa-lg fa-fw d-lg-none"></i>
        <span
          class="sr-only"
          v-html="$t('menu[3]')"
        ></span>
        <span
          class="d-none d-lg-inline text-nowrap"
          v-html="$t('menu[3]')"
        ></span>
      </b-nav-item>

      <b-nav-item :to="`/${$t('lang')}/sponsors`">
        <i class="far fa-handshake fa-lg fa-fw d-lg-none"></i>
        <span
          class="sr-only"
          v-html="$t('menu[4]')"
        ></span>
        <span
          class="d-none d-lg-inline text-nowrap"
          v-html="$t('menu[4]')"
        ></span>
      </b-nav-item>

      <b-nav-item :to="`/${$t('lang')}/stats`">
        <i class="fas fa-heart-pulse fa-lg fa-fw d-lg-none"></i>
        <span
          class="sr-only"
          v-html="$t('menu[5]')"
        ></span>
        <span
          class="d-none d-lg-inline text-nowrap"
          v-html="$t('menu[5]')"
        ></span>
      </b-nav-item>

      <b-nav-item>
        <I18n />
      </b-nav-item>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import I18n from '../../commons/components/I18n.vue';

export default {
  components: {
    I18n,
  },
};
</script>
