<template>
<section id="c2024-thermometer">
	<div class="thermometer-container">
		<div class="progress vertical">
			<div id="fs-progress-thermometer" ref="progressThermometer" class="progress-bar progress-bar-info" role="progressbar" :aria-label="$t('c2024.intro.progressbar')" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;">
				<span id="fs-step-info-mobile" ref="stepInfoMobile" class="position-absolute"></span>
		</div>  
	</div>
	<img class="thermometer-png" src="../../../../public/img/campaign/2024/thermometer.png" alt=""/>
	<a
			class="btn btn-lg btn-soutenir
				   align-items-center justify-content-center btn-step-2"
			href="#support">
			<span id="fs-step-info" ref="stepInfo"></span>
			<span v-html="$t('c2024.intro.step2')"></span>
	</a>
	<!--<a
			class="btn btn-lg btn-soutenir
				   align-items-center justify-content-center btn-step-1"
			href="#support"
			>
			<span>
				<i class="fa fa-heart"></i>
				<span v-html="$t('c2024.intro.step1')"></span>
			</span>
	</a>-->
	<a
			class="btn btn-lg btn-soutenir
				   align-items-center justify-content-center btn-thermo-soutenir"
			href="#support"
			>
			<span>
				<i class="fa fa-heart"></i>
				<span v-html="$t('c2024.intro.support')"></span>
			</span>
	</a>
</div>
</section>
</template>

<script>
export default {
  data() {
    return {
		donations: 0, // real stats (from 2022.json)
		oneDay: 24 * 60 * 60 * 1000, // hours*minutes*seconds*milliseconds
		today: new Date(),
		endCampaign: new Date(2024, 11, 31),
		max: 400000, // campaign target
		numberFormat: new Intl.NumberFormat(undefined, { style: "currency", currency: "EUR", roundingMode: 'ceil', maximumFractionDigits: 0 }),
    };
  },

  created() {
    fetch('https://soutenir.framasoft.org/campagne-fin-2024.json')
      .then(res => res.json())
      .then((data) => {
		if (this.donations === 0) {
			this.donations = data.total;
		}
      })
      .catch((err) => { console.error(err) }); // eslint-disable-line
  },
  computed: {
	nbDays() {
		return Math.round(Math.abs((this.today - this.endCampaign) / this.oneDay))
	}
  },
  watch: {
	donations(newValue, oldValue) {
		console.debug('donations changed', newValue, oldValue);
		const formattedNumber = this.numberFormat.format(this.donations);
		this.$refs.stepInfo.innerHTML = ` 🎈${formattedNumber}🎈<br> ${this.nbDays}`;
		this.$refs.stepInfoMobile.innerHTML = ` 🎈${formattedNumber}🎈`;
		const percent = Math.round(80 * this.donations / this.max + 20);
		console.debug('new percent', percent);
		this.$refs.progressThermometer.style.width = `${percent}%`;
		this.$refs.progressThermometer.setAttribute("aria-valuenow", percent);
	}
  }
};
</script>

<style lang="scss">
#c2024-thermometer {

}
.thermometer-container {
		}
		.vertical {
			display: inline-block;
			border-radius: 20px!important;
			position: absolute;
			top: 372px;
			right: -12px;
			box-shadow: inset 0px 4px 6px #ccc;
			@media (min-width: 1241px) {
				height: 100px!important;
				width: 530px;
				transform: rotate(-81deg);
			}
			@media (max-width: 1540px) {
			right: -100px;
			}
			@media (max-width: 1240px) {
				height: 20px!important;
				width: 90vw;
				top: 2rem;
				left: 5vw;
			}
			@media (max-width: 992px) {
				height: 20px!important;
				width: 90vw;
				top: 2rem;
				left: 5vw;
			}
			@media (max-width: 762px) {
				height: 15px!important;
				width: 90vw;
				top: 2rem;
				left: 5vw;
			}
		}

		.progress-bar {
			box-shadow: inset 0px 4px 6px rgba(100,100,100,0.6);

			@media (max-width: 1240px) {
				color: black !important;
				background-color: #fc8e3a !important;
			}

			#fs-step-info-mobile {
				font-size: 0.95rem;
				left: 35vw;

				@media (min-width: 1240px) {
					display:none;
				}
			}
		}

		.thermometer-png {
			position: absolute;
			top: 18px;
			right: 93px;
			@media (max-width: 1540px) {
			right: 10px;
			}
			@media (max-width: 1240px) {
				display:none;
			}
		}
		.btn-step-1 {
			position: absolute;
			right: 15em;
			top: 20em;
			width: 8em;
			@media (max-width: 1540px) {
			right: 10em;
			}
			@media (max-width: 1240px) {
				display:none!important;
			}
		}

		.btn-step-2 {
			position: absolute;
			right: 15em;
			top: 3em;
			width: 11em;
			@media (max-width: 1540px) {
				right: 8em;
			}
			@media (max-width: 1240px) {
				display:none!important;
			}
		}

		.btn-thermo-soutenir {
			position: absolute;
			right: 10em;
			top: 36.5em;
			width: 8em;
			@media (max-width: 1540px) {
				right: 5em;
			}
			@media (max-width: 1240px) {
				top: 6.5rem;
				right: 2.5rem;
			}
			@media (max-width: 762px) {
				top: 14rem;
				right: calc(50% - 90px);
			}
			@media (max-width: 632px) {
				top: 17rem;
			}
			@media (max-width: 480px) {
				top: 25rem;
			}

		}

</style>
