<template>
  <main class="home">
    <campaign />
  </main>
</template>

<script>
import campaign from './campaign/2021/Campaign.vue';

export default {
  components: {
    campaign,
  },
};
</script>
