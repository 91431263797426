<template>
  <section
    id="c2023-roster"
  >
    <header>
      <b-container
        class="px-md-0"
      >
        <b-row>
          <b-col>
            <h2
              class="text-center"
            >
              <span v-html="$t('c2023.roster.title1')"></span>
              <a
                href="#"
                @click.prevent.stop="tabs.fight = 1; chosen(tabs.tabindex + 1)"
                v-html="$t('c2023.roster.action')"
              ></a>
              <span v-html="$t('c2023.roster.title2')"></span>
            </h2>
          </b-col>
        </b-row>
      </b-container>
    </header>
    <div>
      <b-container
        class="px-md-0"
      >
        <b-row>
          <b-col>
            <b-tabs
              v-model="tabs.tabindex"
              :nav-class="`py-2 px-0 justify-content-center
                col-9 col-md-7
                col-lg-5`"
              nav-wrapper-class="row justify-content-center"
              card
              fill
              justified
            >
              <b-tab
                v-for="n in $t('c2023.roster.tab').length"
                :key="n"
                :active="n === (tabs.tabindex + 1)"
                class="py-2 px-0"
                @click="chosen(n)"
              >
                <template #title>
                  <img
                    :alt="$t(`c2023.roster.tab[${n - 1}].mascot`)"
                    :src="`${$t('baseurl')}img/campaign/2023/UI/selector/0${n}${
                      blog[n - 1].on ? '' : '-off'
                    }.png`"
                    :title="$t(`c2023.roster.tab[${n - 1}].label`)"
                  />
                </template>
                <!-- Fight -->
                <b-row
                  v-show="tabs.fight"
                  ref="f-fight"
                  align-h="around"
                  align-v="end"
                  aria-hidden="true"
                >
                  <!-- Mascot -->
                  <b-col
                    cols="6"
                    :class="`text-center mascot
                      d-flex flex-column justify-content-end
                      ${blog[n - 1].on ? 'unlocked animate' : 'locked'}
                      ${tabs.filename[n-1]} boost-${level}`"
                  >
                    <div class="align-self-center">
                      <a
                        href="#"
                        @click.prevent.stop="blog[n - 1].on ? boost() : false"
                      >
                        <div class="img-wrapper">
                          <img
                            alt=""
                            :src="`${$t('baseurl')
                            }img/campaign/2023/characters/0${n}-${tabs.filename[n-1]}.png`"
                          />
                          <img
                            alt=""
                            class="blink"
                            :src="`${$t('baseurl')
                            }img/campaign/2023/characters/0${n}-${tabs.filename[n-1]
                            }-a.png`"
                          />
                          <img
                            v-if="/^(2|5|6|7|8)$/.test(n)"
                            alt=""
                            :src="`${$t('baseurl')
                            }img/campaign/2023/characters/0${n}-${tabs.filename[n-1]
                            }-c.png`"
                          />
                          <img
                            v-show="duckface"
                            alt=""
                            :src="`${$t('baseurl')
                            }img/campaign/2023/characters/0${n}-${tabs.filename[n-1]
                            }-b.png`"
                          />
                        </div>
                      </a>
                    </div>
                    <button
                      class="btn"
                      @click.prevent.stop="blog[n - 1].on ? boost() : false"
                    >
                      <b-progress
                        variant="warning"
                        striped
                      >
                        <b-progress-bar
                          :value="duckface ? 100 : level * 30 + 20"
                          :label-html="`<span>${duckface ? '🦆' : '🧡'}</span>`"
                        />
                      </b-progress>
                    </button>
                  </b-col>
                  <!-- Monster -->
                  <b-col
                    cols="6"
                    :class="`text-center monster
                      d-flex flex-column justify-content-end
                      animate ${monsterClass}`"
                  >
                    <div class="align-self-start">
                      <a
                        href="#"
                        @click.prevent.stop="fight()"
                      >
                        <div class="img-wrapper">
                          <img
                            alt=""
                            :src="`${$t('baseurl')
                            }img/campaign/2023/characters/0${n}-monster.png`"
                          />
                        </div>
                      </a>
                    </div>

                    <button
                      class="btn"
                      @click.prevent.stop="fight()"
                    >
                      <b-progress
                        variant="dark"
                        striped
                      >
                        <b-progress-bar
                          :value="(monsterClass === 'die') ? 0 : 100 - hit * 30"
                          :label-html="`<span>${(monsterClass === 'die') ? '👻' : '😱'}</span>`"
                        />
                      </b-progress>
                    </button>
                  </b-col>
                </b-row>
                <!-- VS bar -->
                <b-container
                  v-show="tabs.fight"
                  class="p-0"
                >
                  <b-row
                    align-h="around"
                    tag="h3"
                  >
                    <b-col
                      cols="12"
                      md="6"
                      class="d-flex justify-content-between align-items-center"
                    >
                      <button
                        class="btn btn-link invisible-md"
                        :title="$t(`c2023.roster.tab[${n === 1 ? 7 : n - 2}].label`)"
                        @click="prev()"
                      >
                        <i class="fas fa-2x fa-chevron-left"></i>
                        <span
                          class="sr-only"
                          v-html="$t(`c2023.roster.tab[${n === 1 ? 7 : n - 2}].label`)"
                        ></span>
                      </button>
                      <span
                        v-if="blog[n - 1].on"
                        class="mt-2"
                        v-html="$t(`c2023.roster.tab[${n - 1}].mascot`)"
                      ></span>
                      <span v-else>
                        <i class="fas fa-xs fa-lock"></i>
                        <span v-html="$t('c2023.locked')"></span><br />
                        <small
                          v-html="$t('c2023.unlocked', {
                            weeks: blog[n - 1].weeks,
                            s: blog[n - 1].s
                          })"
                        ></small>
                      </span>
                      <img
                        alt="VS"
                        :src="`${$t('baseurl')}img/campaign/2023/UI/versus-sprite.png`"
                      />
                    </b-col>
                    <b-col
                      cols="12"
                      class="d-flex d-md-none justify-content-between align-items-center"
                    >
                      <img
                        alt="VS"
                        :src="`${$t('baseurl')}img/campaign/2023/UI/versus-sprite.png`"
                      />
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                      class="d-flex justify-content-between align-items-center"
                    >
                      <img
                        alt="VS"
                        :src="`${$t('baseurl')}img/campaign/2023/UI/versus-sprite.png`"
                      />
                      <span
                        class="mt-2"
                        v-html="$t(`c2023.roster.tab[${n - 1}].monster`)"
                      ></span>
                      <button
                        class="btn btn-link invisible-md"
                        :title="$t(`c2023.roster.tab[${n}].label`)"
                        @click="next()"
                      >
                        <i class="fas fa-2x fa-chevron-right"></i>
                        <span
                          class="sr-only"
                          v-html="$t(`c2023.roster.tab[${n}].label`)"
                        ></span>
                      </button>
                    </b-col>
                  </b-row>
                </b-container>
                <!-- Description -->
                <b-row
                  v-show="tabs.fight"
                  ref="c2023-what"
                  align-h="center"
                  class="c2023-what"
                >
                  <b-col
                    class="py-3 d-flex justify-content-between align-items-center"
                  >
                    <button
                      :id="`prev-${n}`"
                      class="btn btn-link d-none d-md-block"
                      @click="prev()"
                    >
                      <i class="fas fa-3x fa-chevron-left"></i>
                      <span
                        class="sr-only"
                        v-html="$t(`c2023.roster.tab[${n === 1 ? 7 : n - 2}].label`)"
                      ></span>
                      <b-popover
                        custom-class="prev-next"
                        placement="right"
                        :target="`prev-${n}`"
                        triggers="hover"
                      >
                        <template #title>
                          <div class="row no-gutters align-items-center">
                            <div class="col-4">
                              <img
                                alt=""
                                class="w-100 rounded"
                                :src="`${$t('baseurl')}img/campaign/2023/UI/selector/0${
                                  n === 1 ? 8 : n - 1}${
                                  blog[n === 1 ? 7 : n - 2].on ? '' : '-off'
                                }.png`"
                              />
                            </div>
                            <div class="col-8">
                              <div
                                class="p-2"
                                v-html="$t(`c2023.roster.tab[${n === 1 ? 7 : n - 2}].label`)"
                              ></div>
                            </div>
                          </div>
                        </template>
                      </b-popover>
                    </button>
                    <div class="py-3 px-4 description">
                      <img
                        :alt="$t(`c2023.roster.tab[${n - 1}].mascot`)"
                        class="d-block mx-auto mb-4 ml-md-4 float-md-right"
                        :src="`${$t('baseurl')}img/campaign/2023/UI/selector/0${n}${
                          blog[n - 1].on ? '' : '-off'
                        }.png`"
                        :title="$t(`c2023.roster.tab[${n - 1}].label`)"
                      />
                      <div v-html="$t(`c2023.roster.tab[${n - 1}].desc`)"></div>
                      <p
                        v-if="blog[n - 1].on"
                        class="my-4"
                      >
                        <a
                          :href="`https://framablog.org/?p=${
                            blog[n - 1].link[/^fr/.test($t('lang')) ? 'fr' : 'en']
                          }`"
                        >
                          <b
                            v-html="$t(`c2023.roster.tab[${n - 1}].more`)"
                          ></b>
                        </a>
                      </p>
                      <p
                        v-else
                        class="my-4"
                      >
                        <b v-html="$t(`c2023.roster.tab[${n - 1}].more`)"></b>
                        <small class="text-warning">
                          <i
                            v-html="$t('c2023.soon', {
                              weeks: blog[n - 1].weeks,
                              s: blog[n - 1].s
                            })"
                          ></i>
                        </small>
                      </p>

                      <p class="text-center">
                        <a
                          href="#support"
                          class="btn btn-warning"
                          @click.prevent.stop="$parent.$parent.$refs['f-sfs']
                            .scrollIntoView({ behavior: 'smooth' }); selectWallpaper(n)"
                        >
                          <b v-html="$t('c2023.support')"></b>
                        </a>
                      </p>
                    </div>
                    <button
                      :id="`next-${n}`"
                      class="btn btn-link d-none d-md-block"
                      @click="next()"
                    >
                      <i class="fas fa-3x fa-chevron-right"></i>
                      <span
                        class="sr-only"
                        v-html="$t(`c2023.roster.tab[${n}].label`)"
                      ></span>
                    </button>
                    <b-popover
                      custom-class="prev-next"
                      :target="`next-${n}`"
                      triggers="hover"
                      placement="right"
                    >
                      <template #title>
                        <div class="row no-gutters align-items-center">
                          <div class="col-4">
                            <img
                              alt=""
                              class="w-100 rounded"
                              :src="`${$t('baseurl')}img/campaign/2023/UI/selector/0${
                                n === 8 ? 1 : n + 1}${
                                blog[n === 8 ? 0 : n].on ? '' : '-off'
                              }.png`"
                            />
                          </div>
                          <div class="col-8">
                            <div
                              class="p-2"
                              v-html="$t(`c2023.roster.tab[${n === 8 ? 0 : n}].label`)"
                            ></div>
                          </div>
                        </div>
                      </template>
                    </b-popover>
                  </b-col>
                </b-row>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </b-container>
      <footer>
        <b-container
          class="px-md-0"
        >
          <b-row>
            <b-col>
              <p
                class="credits"
                v-html="$t('c2023.credits', {
                  author: '<a href=\'http://www.davidrevoy.com\'>David Revoy</a>',
                  author2: '<a href=\'https://wiki.wesnoth.org/Wesnoth:Copyrights\'>Wesnoth</a>'
                })"
              ></p>
            </b-col>
          </b-row>
        </b-container>
        <div class="hidden">
          <audio
            ref="boost-1"
            :src="`${$t('/')}img/campaign/2023/sounds/boost-1.ogg`"
          ></audio>
          <audio
            ref="boost-2"
            :src="`${$t('/')}img/campaign/2023/sounds/boost-2.ogg`"
          ></audio>
          <audio
            ref="boost-3"
            :src="`${$t('/')}img/campaign/2023/sounds/boost-3.ogg`"
          ></audio>
          <audio
            ref="die-1"
            :src="`${$t('/')}img/campaign/2023/sounds/orc-die-1.ogg`"
          ></audio>
          <audio
            ref="die-2"
            :src="`${$t('/')}img/campaign/2023/sounds/orc-die-2.ogg`"
          ></audio>
          <audio
            ref="die-3"
            :src="`${$t('/')}img/campaign/2023/sounds/orc-die-3.ogg`"
          ></audio>
          <audio
            ref="hit-1"
            :src="`${$t('/')}img/campaign/2023/sounds/orc-hit-1.ogg`"
          ></audio>
          <audio
            ref="hit-2"
            :src="`${$t('/')}img/campaign/2023/sounds/orc-hit-2.ogg`"
          ></audio>
          <audio
            ref="hit-3"
            :src="`${$t('/')}img/campaign/2023/sounds/orc-hit-3.ogg`"
          ></audio>
          <audio
            ref="hit-4"
            :src="`${$t('/')}img/campaign/2023/sounds/orc-hit-4.ogg`"
          ></audio>
          <audio
            ref="c42"
            :src="`${$t('/')}img/campaign/2023/sounds/morning.ogg`"
          ></audio>
        </div>
      </footer>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    let active = 1;
    const blog = [
      { date: '2023/11/09', link: { fr: '32491', en: '32493' } }, // coincoin
      { date: '2023/11/22', link: { fr: '32495', en: '32497' } }, // space
      { date: '2023/11/28', link: { fr: '32499', en: '32501' } }, // ptv6
      { date: '2023/12/05', link: { fr: '32503', en: '32505' } }, // mbz
      { date: '2023/12/12', link: { fr: '32507', en: '32509' } }, // pt 2024
      { date: '2023/12/19', link: { fr: '32511', en: '32513' } }, // emancipasso
      { date: '2023/12/26', link: { fr: '32515', en: '32517' } }, // libre
      { date: '2023/12/31', link: { fr: '32520', en: '32522' } }, // dio
    ];

    for (let i = 0; i < blog.length; i += 1) {
      blog[i].weeks = Math.round(((new Date(blog[i].date)).getTime() - (new Date()).getTime())
        / (1000 * 60 * 60 * 24 * 7));
      blog[i].s = blog[i].weeks > 1 ? 's' : '';
      if (this.is.after(blog[i].date)) {
        blog[i].on = true;
        active = i + 1;
      } else {
        blog[i].on = false;
      }
    }

    return {
      blog,
      clic: 0,
      duckface: false,
      hit: 0,
      level: 0,
      monsterClass: '',
      tabs: {
        action: 0,
        count: this.$t('c2023.roster.tab').length,
        fight: 0,
        filename: [
          'coincoin', 'framaspace', 'sepia-v6', 'rose',
          'super-sepia', 'emancipasso-lynx', 'gnu-linux', 'elephant',
        ],
        tabindex: active - 1,
      },
      wallpaper: active,
    };
  },

  mounted() {
    const kkeys = [];
    const shortcut = ['arrowup', 'arrowup', 'arrowdown', 'arrowdown',
      'arrowleft', 'arrowright', 'arrowleft', 'arrowright', 'b', 'a'];

    window.addEventListener('keydown', (e) => {
      if (shortcut.includes(e.key.toLowerCase())) {
        kkeys.push(e.key.toLowerCase());
        if (kkeys.length === 10 && kkeys.join() === shortcut.join()) {
          this.unlockAll();
        }
      } else {
        kkeys.splice(0, kkeys.length);
      }
    }, true);

    document.body.classList.add(`w-0${this.wallpaper}`);
    this.cookie('w', 'f-wallpaper', this.wallpaper, 604800000);
  },

  methods: {
    boost() {
      if (this.level < 3) {
        /* Sound */
        const fileBoost = `boost-${this.level + 1}`;

        this.$refs[fileBoost].play();
        this.level += 1;
        this.duckface = (this.level === 3);
        this.clic += +(this.clic <= 42);
        if (this.clic === 42) {
          this.$refs.c42.play();
        }
      } else {
        this.fight();
      }
    },

    chosen(index) {
      if (!this.tabs.fight) {
        this.tabs.fight = 1;
        this.selectWallpaper(index);
      }
      this.level = 0;
      this.hit = 0;
      this.duckface = false;
      setTimeout(() => {
        this.$refs['f-fight'][this.tabs.tabindex].scrollIntoView({ behavior: 'smooth' });
      }, 500);
    },

    fight() {
      /* Sound */
      const fileHit = `hit-${Math.floor(Math.random() * Math.floor(4)) + 1}`;
      const fileDie = `die-${Math.floor(Math.random() * Math.floor(3)) + 1}`;

      if (this.hit > 2) {
        this.$refs[fileDie].play();
        this.hit = 0;
        this.monsterClass = 'die';
        setTimeout(() => {
          this.monsterClass = 'comeback';
          setTimeout(() => {
            this.monsterClass = '';
            this.$refs['c2023-what'][this.tabs.tabindex].scrollIntoView({ behavior: 'smooth' });
          }, 3000);
        }, 3000);
      } else {
        this.$refs[fileHit].play();
        this.hit += 1;
        this.monsterClass = 'hit';
        setTimeout(() => {
          this.monsterClass = '';
        }, 1000);
      }

      this.clic += +(this.clic <= 42);
      if (this.clic === 42) {
        this.$refs.c42.play();
      }
    },

    prev() {
      if (this.tabs.tabindex !== 0) {
        this.tabs.tabindex -= 1;
      } else {
        this.tabs.tabindex = this.blog.length - 1;
      }
      this.chosen();
    },

    next() {
      if (this.tabs.tabindex !== this.blog.length - 1) {
        this.tabs.tabindex += 1;
      } else {
        this.tabs.tabindex = 0;
      }
      this.chosen();
    },

    selectWallpaper(index) {
      this.wallpaper = index;
      document.body.classList.remove('w-01', 'w-02', 'w-03', 'w-04',
        'w-05', 'w-06', 'w-07', 'w-08');
      document.body.classList.add(`w-0${this.wallpaper}`);
      this.cookie('w', 'f-wallpaper', this.wallpaper, 604800000);
      this.$parent // Campaign2023.vue
        .$parent // Home.vue
        .$children[1] // sfs/Main.vue
        .$children[0] // sfs/Form.vue
        .form.state.mascot = this.wallpaper;
    },

    unlockAll() {
      for (let i = 0; i < this.blog.length; i += 1) {
        this.blog[i].on = true;
      }
    },
  },
};
</script>

<style lang="scss">
#c2023-roster {
  --f-coin: var(--f-o4);
  --f-licorne: var(--f-b4);
  --f-sepia: var(--f-o4);
  --f-rose: var(--f-f4);
  --f-sepiaf: var(--f-o6);
  --f-lynx: var(--f-b5);
  --f-dio: var(--f-f5);
  --f-gt: var(--f-v4);
  --f-monster: var(--f-r9);

  > div{
    position: relative;
    padding: 5rem 0 4rem;

    &::after {
      background: #ffffa7;
      background-image: url('../../../../public/img/campaign/2023/background.jpg');
      background-repeat: no-repeat;
      background-position: top center;
      background-size: cover;
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  header {
    background: #4c255a;
    padding: 3rem 0 0;

    h2 {
      color: #ffffffee;
      font-family: 'Tovari Sans';
      font-size: 2.5rem;
      line-height: 2.25rem;
      margin: 0 auto;

      @media (min-width: 768px) {
        font-size: 3.5rem;
        line-height: 3rem;
        margin: 1rem auto;
      }

      a {
        background: var(--f-b5);
        border-radius: .5rem;
        color: #fff;
        font-size: smaller;
        padding: .5rem .75rem .25rem;

        &::after {
          --icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M318.4 304.5c-3.531 9.344-12.47 15.52-22.45 15.52h-105l45.15 94.82c9.496 19.94 1.031 43.8-18.91 53.31c-19.95 9.504-43.82 1.035-53.32-18.91L117.3 351.3l-75 88.25c-4.641 5.469-11.37 8.453-18.28 8.453c-2.781 0-5.578-.4844-8.281-1.469C6.281 443.1 0 434.1 0 423.1V56.02c0-9.438 5.531-18.03 14.12-21.91C22.75 30.26 32.83 31.77 39.87 37.99l271.1 240C319.4 284.6 321.1 295.1 318.4 304.5z"/></svg>');
          background-color: var(--f-b1);
          content: '';
          display: inline-block;
          line-height: 1;
          mask-image: var(--icon);
          mask-position: center center;
          mask-repeat: no-repeat;
          mask-size: contain;
          width: .7em;
          height: .7em;
          -webkit-mask-image: var(--icon);
          -webkit-mask-position: center center;
          -webkit-mask-repeat: no-repeat;
          -webkit-mask-size: contain;
          position: absolute;
          margin: 1.5rem -1.5rem;
          opacity: .8;
        }
        &:hover, &:focus {
          text-decoration: none;
          background: var(--f-b6);
        }
      }
    }
  }

  footer {
    .credits {
      font-size: 80%;
      margin: 5rem 0 0;
      padding: 0 1.5rem;
      text-align: right;
    }
  }

  * {
    z-index: 1;
  }

  .card-header {
    --avatar-w: 50px;
    @media (min-width: 768px) {
      --avatar-w: 80px;
    }

    border: none;
    background: transparent;
    padding: 0;

    .nav {
      background: #ffffff66;
      border-radius: .25rem;
      display:flex;
      flex-wrap: wrap;
      padding-right: 0;

      li {
        background: transparent;
        flex: 1 0 21%;
        text-align: center;
        max-width: calc(var(--avatar-w) + 20px);

        a {
          display: inline-block;
          margin: 2px;
          padding: 5px;

          &.active {
            border-color: transparent;
            background: var(--f-o4);
            border-radius: .25rem;
            border: none;
          }
        }

        &:nth-child(1) .active { background: var(--f-coin); }
        &:nth-child(2) .active { background: var(--f-licorne); }
        &:nth-child(3) .active { background: var(--f-sepia); }
        &:nth-child(4) .active { background: var(--f-rose); }
        &:nth-child(5) .active { background: var(--f-sepiaf); }
        &:nth-child(6) .active { background: var(--f-lynx); }
        &:nth-child(7) .active { background: var(--f-dio); }
        &:nth-child(8) .active { background: var(--f-gt); }
      }

      img {
        max-width: var(--avatar-w);
      }
    }
  }

  .c2023-what {
    .btn-link {
      background: transparent;

      .fas::before {
        color: var(--f-b7);
      }
    }
  }

  .description {
    background: #ffffffcc;
    border: none;
    border-radius: .25rem;
    box-shadow: 0px 2px 5px var(--f-b5);
    max-width: 800px;

    img {
      border: 5px solid var(--f-o3);
      border-radius: .25rem;
      margin: .5rem 0rem .5em 1rem;
      max-width: 120px;
    }
  }

  h3 {
    color: var(--f-g10);
    font-family: 'Tovari Sans', sans-serif;
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 1;
    margin: 0.5rem 0 1rem;

    @media (max-width: 992px) {
      font-size: 2rem;
    }

    @media (max-width: 768px) {
      font-size: 1.75rem;
    }
  }

  p, li {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.3;

    @media (max-width: 992px) {
      font-size: 1rem;
    }
  }

  .tab-pane {
    .mascot, .monster {
      a {
        position: relative;
      }

      a::after { /* ground shadow */
        background: var(--f-g8);
        border-radius: 50%;
        content: '';
        display:block;
        filter: blur(2rem);
        margin: -50px auto 0;
        opacity: 0.4;
        // position: absolute;
        transform: scale(1, 0.3);
        width: 85%;
        height: 100px;
        z-index: -1;

        @media (min-width: 768px) {
          margin-top: -50px;
        }
      }

      button {
        box-shadow: none !important;
        margin-bottom: .5rem;
        max-width: 100px;
        @media (min-width: 768px) {
          max-width: 200px;
        }
        padding: 0;
        width: 100%;
      }

      .progress {
        margin-bottom: 1rem;
        margin-left: 1rem;
        width: 100%;
      }

      .progress-bar {
        span {
          font-size: 1.5rem;
          margin-left: -1.25rem;
          position: absolute;
          text-shadow: 0 0 5px var(--f-g9);
          transform: scaleX(-1);
        }
      }
    }

    .mascot {
      flex-wrap: wrap;

      a::after { /* ground shadow */
        max-width: 250px;
      }

      .img-wrapper {
        position: relative;
        display: inline-block;
      }

      img {
        max-height: 200px;

        @media (min-width: 768px) {
          max-height: 350px;
        }

        &[src*="-a."],
        &[src*="-b."],
        &[src*="-c."] {
          position: absolute;
          top: 0;
          left: 0;
        }

        &.blink {
          animation: blink 5s steps(3, start) infinite;
        }
      }

      .progress {
        background: var(--f-o3);
        border: 1px solid var(--f-o5);
      }

      &.boost-3 button {
        max-width: 175px;
        @media (min-width: 768px) {
          max-width: 300px;
        }
      }
    }

    .monster {
      img {
        max-height: 300px;
        translate: 0 .5rem;

        @media (min-width: 768px) {
          max-height: 500px;
        }

        &[src*="02-monster"] {
          translate: 0 0;
        }

        &[src*="07-monster"] {
          translate: 0 1.5rem;

          @media (min-width: 768px) {
            translate: 0 2.5rem;
          }
        }
      }

      a::after { /* ground shadow */
        opacity: 0.6;
        transform: scale(1, 0.3);
      }

      button {
        align-self: end;
        transform: scaleX(-1);
        max-width: 175px;
        @media (min-width: 768px) {
          max-width: 300px;
        }
      }

      .progress {
        background: var(--f-g3);
        border: 1px solid var(--f-g5);
      }
    }

    .btn.position-absolute {
      background: transparent;
      right: 1rem;
      top: 1rem;

      .fas::before {
        color: var(--f-g6);
      }
    }

    .container h3 {
      align-items: center;
      background: var(--f-o6);
      border-radius: 0.5rem;
      color: #fff;
      display: flex;
      font-family: "Tovari Sans", sans-serif;
      font-weight: bold;
      margin: 0 auto 2rem;
      padding: 0;
      position: relative;
      text-align: center;
      text-transform: uppercase;
      text-shadow: none;

      font-size: 1.5rem;
      line-height: 1.25rem;
      max-height: 150px;

      @media (min-width: 768px) {
        font-size: 2.5rem;
        line-height: 2.25rem;
      }

      small {
        display: block;
        font-family: Roboto, sans-serif;
        font-size: .8rem;
        @media (min-width: 768px) {
          font-size: 1rem;
        }
        font-style: italic;
        line-height: 1;
        text-transform: none;
      }

      img {
        height: 3rem;
        opacity: 0;

        @media (min-width: 768px) {
          height: 4rem;
        }

        &:first-child {
          translate: calc(-15px - 50%) 0;
          @media (min-width: 768px) {
            opacity: 1;
            scale: 1.5;
          }
        }
      }

      div:nth-of-type(2) img {
        opacity: 1;
        translate: 0 0;
        margin: 0 auto;
      }

      .btn {
        background: transparent;

        .fas::before {
          color: var(--f-o3);
        }
      }
    }

    .mascot {
      > div > a {
        filter: brightness(0) opacity(.6) drop-shadow(0px 0px 10px #575186);
      }

      &::after {
        bottom: 6rem;
        content: '?';
        color: var(--f-g3);
        display: block;
        font-size: 5rem;
        font-family: 'Tovari Sans', sans-serif;
        opacity: .6;
        position: absolute;
        text-align: center;
        text-shadow: 0 0 5px #000;
        width: 100%;
        z-index: 1;

        @media (min-width: 768px) {
          font-size: 10rem;
        }
      }
    }

    .mascot.unlocked {
      > div > a {
        filter: none;
      }

      &::after {
        content: none;
      }
    }

    .animate {
      &.coincoin,
      &.framaspace,
      &.rose,
      &.sepia-v6,
      &.super-sepia,
      &.monster {
        .img-wrapper {
          animation-duration: 5s;
          animation-iteration-count: infinite;
          animation-timing-function: ease-in-out;
        }
      }
    }

    .rose .img-wrapper {
      animation-name: h-float;
    }

    .coincoin .img-wrapper {
      animation-name: h-float;
    }

    .framaspace .img-wrapper {
      animation-name: x-float;
    }

    .animate.framaspace .img-wrapper {
      img[src*="-c_"] {
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-name: v-float;
        animation-timing-function: ease-in-out;
      }
    }

    .sepia-v6 .img-wrapper {
      animation-name: v-float;
    }

    .super-sepia .img-wrapper {
      animation-name: v-float;
    }

    .animate.super-sepia .img-wrapper {
      img[src*="-c_"] {
        animation-duration: .5s;
        animation-iteration-count: infinite;
        animation-name: blink;
        animation-timing-function: ease-in-out;
      }
    }

    .animate.emancipasso-lynx .img-wrapper,
    .animate.gnu-linux .img-wrapper,
    .animate.elephant .img-wrapper {
      img[src*="-c_"] {
        animation: blink2 7s steps(3, start) infinite;
      }
    }

    .mascot .img-wrapper img:first-child {
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    }

    .mascot.boost-1 .img-wrapper img:first-child {
      animation-name: boost1;
    }

    .mascot.boost-2 .img-wrapper img:first-child {
      animation-name: boost2;
    }

    .mascot.boost-3 .img-wrapper img:first-child {
      animation-name: boost3;
    }

    .monster .img-wrapper {
      animation-name: pivot;
    }

    .monster.hit {
      filter: drop-shadow(0 -.25rem .125rem var(--f-monster));

      .img-wrapper {
        animation-duration: 1s;
        animation-name: hit;
      }
    }

    .monster.die .img-wrapper {
      animation-name: die;
      animation-duration: 3s;
    }

    .monster.comeback .img-wrapper {
      animation-name: comeback;
      animation-duration: 3s;
    }
  }

  @keyframes v-float {
    0% { transform: translate(0, 0); }
    75%  { transform: translate(0, .25rem); }
    85%  { transform: translate(-.125rem, .125rem); }
    100%   { transform: translate(0, 0); }
  }

  @keyframes h-float {
    0% { transform: translate(0, 0); }
    15%  { transform: translate(.125rem, -.125rem); }
    30%  { transform: translate(.25rem, 0); }
    100%   { transform: translate(0, 0); }
  }

  @keyframes x-float {
    0% { transform: translate(0, 0); }
    40%  { transform: translate(.25rem, .5rem); }
    75%  { transform: translate(.5rem, .25rem); }
    100%   { transform: translate(0, 0); }
  }

  @keyframes pivot {
    0% { transform: rotate(0) translate(0, 0); }
    75% { transform: rotate(.125deg) translate(.125rem, 0); }
    100% { transform: translate(0, 0); }
  }

  @keyframes hit {
    0% { transform: translateX(0); }
    6.5% { transform: translateX(-6px) rotateY(-9deg); }
    18.5% { transform: translateX(5px) rotateY(7deg); }
    31.5% { transform: translateX(-3px) rotateY(-5deg); }
    43.5% { transform: translateX(2px) rotateY(3deg); }
    50% { transform: translateX(0); }
  }

  @keyframes die {
    0% { opacity: 1; }
    100% { opacity: 0; transform: translate3d(100%, 0, 0) rotate(120deg) }
  }

  @keyframes comeback {
    0% { opacity: 0; transform: translate3d(100%, 0, 0); }
    100% { opacity: 1; transform: translateZ(0); }
  }

  @keyframes blink {
    0% { visibility: visible; }
    5% { visibility: hidden; }
    95% { visibility: hidden; }
    100% { visibility: visible; }
  }

  @keyframes blink2 {
    0% { visibility: hidden; }
    25% { visibility: visible; }
    30% { visibility: hidden; }
    100% { visibility: hidden; }
  }

  @keyframes boost1 {
    0% { filter: drop-shadow(0 .25rem .25rem var(--f-o4)) }
    50% { filter: drop-shadow(0 .25rem .75rem var(--f-o4)) }
    100% { filter: drop-shadow(0 .25rem .25rem var(--f-o4)) }
  }

  @keyframes boost2 {
    0% { filter: drop-shadow(0 .25rem .5rem var(--f-o5)) }
    50% { filter: drop-shadow(0 .25rem 1rem var(--f-o5)); }
    100% { filter: drop-shadow(0 .25rem .5rem var(--f-o5)) }
  }

  @keyframes boost3 {
    0% { filter: drop-shadow(0 .25rem 1rem var(--f-o6)); }
    50% { filter: drop-shadow(0 .25rem 1.75rem var(--f-o6)); }
    100% { filter: drop-shadow(0 .25rem 1rem var(--f-o6)); }
  }
}

.popover.prev-next {
  max-width: 220px;
}

.invisible-md {
  @media (min-width: 768px) {
    visibility: hidden;
  }
}
/*
#f-sfs .fa-heart {
  background:
    url('../../../../public/img/campaign/2023/UI/selector/01.png')
    no-repeat
    center center;
  background-size: contain;
  border: 5px solid var(--f-o3);
  border-radius: 0.25rem;
  width: 120px;
  height: 120px;
  &::before { visibility: hidden; }
}

.w-01 #f-sfs .fa-heart {
  background-image: url('../../../../public/img/campaign/2023/UI/selector/01.png');
}
.w-02 #f-sfs .fa-heart {
  background-image: url('../../../../public/img/campaign/2023/UI/selector/02.png');
}
.w-03 #f-sfs .fa-heart {
  background-image: url('../../../../public/img/campaign/2023/UI/selector/03.png');
}
.w-04 #f-sfs .fa-heart {
  background-image: url('../../../../public/img/campaign/2023/UI/selector/04.png');
}
.w-05 #f-sfs .fa-heart {
  background-image: url('../../../../public/img/campaign/2023/UI/selector/05.png');
}
.w-06 #f-sfs .fa-heart {
  background-image: url('../../../../public/img/campaign/2023/UI/selector/06.png');
}
.w-07 #f-sfs .fa-heart {
  background-image: url('../../../../public/img/campaign/2023/UI/selector/07.png');
}
.w-08 #f-sfs .fa-heart {
  background-image: url('../../../../public/img/campaign/2023/UI/selector/08.png');
}
*/
</style>
