<template>
  <main>
    <section id="thank-you">
      <b-container class="ombre">
        <b-row align-h="center">
          <b-col
            lg="8"
            xl="9"
          >
            <h1 v-html="$t('merci.title')"></h1>
            <img
              :alt="$t('merci.card')"
              class="img-fluid rounded my-4"
              :src="`${$t('baseurl')}img/${$t('lang')}/thank-you.png`"
            />
            <div v-html="$t('merci.desc')"></div>
            <br />
            <div
              class="alert alert-warning"
              v-html="$t('merci.stop.md')"
            ></div>
            <br />
            <div class="alert alert-info" v-show="is.before('2025/1/2')" v-html="$t('merci.illustrations.md')" />
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section id="share">
      <b-container class="ombre">
        <b-row align-h="center">
          <b-col
            class="pt-5"
            lg="8"
            xl="9"
          >
            <h1 v-html="$t('merci.care.title')"></h1>
            <p v-html="$t('merci.care.desc')"></p>
            <ul class="list-unstyled list-inline text-center">
              <li
                v-for="btn in ['Mastodon', 'Diaspora', 'Bluesky', 'LinkedIn', 'Twitter', 'Facebook']"
                :key="btn"
                class="list-inline-item"
              >
                <a
                  :class="`btn-${btn.toLowerCase()}`"
                  target="_blank"
                  rel="nofollow"
                  :href="share[btn.toLowerCase()]"
                  :title="`${$t('merci.care.shareOn')} ${btn}`"
                >
                  <svg v-if="btn === 'Bluesky'" class="bluesky" width="42" height="42" viewBox="0 0 1024 1024" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M351.121 315.106C416.241 363.994 486.281 463.123 512 516.315C537.719 463.123 607.759 363.994 672.879 315.106C719.866 279.83 796 252.536 796 339.388C796 356.734 786.055 485.101 780.222 505.943C759.947 578.396 686.067 596.876 620.347 585.691C735.222 605.242 764.444 670.002 701.333 734.762C581.473 857.754 529.061 703.903 515.631 664.481C513.169 657.254 512.017 653.873 512 656.748C511.983 653.873 510.831 657.254 508.369 664.481C494.939 703.903 442.527 857.754 322.667 734.762C259.556 670.002 288.778 605.242 403.653 585.691C337.933 596.876 264.053 578.396 243.778 505.943C237.945 485.101 228 356.734 228 339.388C228 252.536 304.134 279.83 351.121 315.106Z" fill="#1185FE"/>
                  </svg>
                  <i v-else
                    :class="`fab fa-${
                      btn.toLowerCase().replace('book', 'book-f')
                    } fa-lg fa-shape-square fa-white`"
                  ></i>
                  <span
                    class="sr-only"
                    v-html="btn"
                  ></span>
                </a>
              </li>
            </ul>
            <div
              v-if="$t('lang') === 'fr'"
            >
              <iframe
                border="0"
                class="border-0"
                height="640"
                width="100%"
                src="https://framaforms.org/livre-dor-temoignages-1684758996"
              ></iframe>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section id="infos">
      <b-container class="ombre">
        <b-row align-h="center">
          <b-col
            class="pt-5"
            lg="8"
            xl="9"
          >
            <h1 v-html="$t('merci.info.title')"></h1>
            <div v-html="$t('newsletter.md')"></div>

            <div class="clearfix mb-2">
              <b-form
                method="post"
                name="subscribeform"
                action="https://listmonk.framasoft.org/subscription/form"
                class="col-md-10 fb-o3 mx-auto my-4 px-4 py-3 rounded"
              >
                <b-input-group class="mb-3">
                  <b-input-group-prepend>
                    <!-- Only decorative-->
                    <b-button
                      aria-hidden="true"
                      class="disabled"
                    >
                      <i class="far fa-envelope fc-g1 fa-fw"></i>
                    </b-button>
                    <label
                      class="sr-only"
                      for="nl-email"
                      v-html="$t('newsletter.your-email')"
                    ></label>
                  </b-input-group-prepend>
                  <b-form-input
                    id="nl-email"
                    v-model="email"
                    name="email"
                    type="email"
                    size="40"
                    :title="$t('newsletter.type-your-email')"
                    :placeholder="$t('newsletter.your-email')"
                  />
                </b-input-group>
                <div class="text-right">
                  <!-- <Unsubscribe :email="email" /> -->
                  <b-button
                    variant="warning"
                    name="subscribe"
                    type="submit"
                    value="subscribe"
                  >
                    <i class="fas fa-bell fc-f1"></i>
                    <span v-html="$t('newsletter.subscribe')"></span>
                  </b-button>
                </div>
                <div style="display: none">
                  <input
                    id="c9c93"
                    checked
                    name="l"
                    type="checkbox"
                    value="c9c93baf-1117-44f4-9e32-a37ed4a97f58"
                  />
                </div>
              </b-form>
            </div>
            <p v-html="$t('merci.info.report', { link: 'https://framadrive.org/s/KGMgBPa7dsXYiro' })"></p>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </main>
</template>

<script>
// import Unsubscribe from '../components/Unsubscribe.vue';

export default {
  components: {
    // Unsubscribe,
  },

  data() {
    const diaspora = [
      'https://share.diasporafoundation.org/?',
      `title=${encodeURIComponent(this.$t('merci.care.share'))}`,
      `&amp;url=${this.$t('link.soutenir')}`,
    ];
    const facebook = [
      'https://www.facebook.com/sharer/sharer.php?',
      `u=${encodeURIComponent(this.$t('link.soutenir'))}`,
      `&title=${encodeURIComponent(this.$t('merci.care.shareTitle'))}`,
      `&description=${encodeURIComponent(this.$t('merci.care.share'))}`,
    ];
    const twitter = [
      'https://twitter.com/intent/tweet?',
      `text=${encodeURIComponent(`${this.$t('merci.care.share')} \n${this.$t('link.soutenir')}`)}`,
    ];
    const mastodon = [
      'https://mastodonshare.com/?',
      `text=${encodeURIComponent(this.$t('merci.care.share'))}`,
      `&url=${this.$t('link.soutenir')}`
    ]
    const linkedin = [
      'https://www.linkedin.com/shareArticle?mini=true',
      `&url=${this.$t('link.soutenir')}`,
      `&title=${encodeURIComponent(this.$t('merci.care.shareTitle'))}`,
      `&summary=${encodeURIComponent(this.$t('merci.care.share'))}`,
    ]
    const bluesky = [
      'https://bsky.app/intent/compose?',
      `&text=${encodeURIComponent(`${this.$t('merci.care.share')} \n${this.$t('link.soutenir')}`)}`
    ]
    const active = this.cookie('r', 'f-wallpaper') || 1;

    return {
      active,
      email: '',
      share: {
        diaspora: diaspora.join(''),
        facebook: facebook.join(''),
        twitter: twitter.join(''),
        mastodon: mastodon.join(''),
        linkedin: linkedin.join(''),
        bluesky: bluesky.join('')
      },
    };
  },
};
</script>

<style lang="scss">
#merci {
  .ombre {
    background: var(--f-g2);
    border-radius: 1.75rem;
    box-shadow: none;
    padding: 2rem;
    position: relative;
    margin: 5rem auto 2rem;
    z-index: 1;
  }

  section {
    padding: .1px; // why, god, why !?

    h1 {
      border-radius: 0.5rem;
      background: var(--f-o6);
      color: #fff;
      font-family: "Tovari Sans", sans-serif;
      font-size: 3rem;
      font-weight: bold;
      line-height: 2.75rem;
      padding: 1.25rem 1.75rem 0.5rem;
      margin: -8rem auto 3rem;
      max-width: 400px;
      position: relative;
      text-align: center;
      text-transform: uppercase;
      text-shadow: none;
      transform: rotate(-2deg);
    }

    img {
      border-radius: .5rem;
      display: block;
      max-width: 600px;
      margin: 1rem auto;
      width: 100%;
    }
  }

  .btn-diaspora, .btn-twitter, .btn-facebook, .btn-mastodon {
    height: max-content;
    display: inline-block;
  }

  .btn-diaspora .fab { color: #464646; }
  .btn-twitter .fab { color: #1da1f2; }
  .btn-facebook .fab { color: #1877f2; }
  .btn-mastodon .fab { color: #3088d4; }
  .btn-linkedin .fab { color: #0a66c2; }
  .bluesky {
    height: var(--square);
    width: var(--square);
    align-items: center;
    justify-content: center;
    vertical-align: bottom;
    font-size: 1.3333333333em;
    line-height: 0.75em;
    display: inline-flex;
  }
}

#thank-you {
  position: relative;

  h1 {
    margin: -6rem auto 2rem !important;
  }

  &::after {
    background: url('../../public/img/fr/thank-you.png') top center no-repeat;
    background-size: cover;
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: blur(40px);
    z-index: 0;
  }
}

#header {
  position: relative;
  z-index: 1;
}

#share {
  position: relative;
  background: var(--f-g4);
}

#infos {
  background: url('../../public/img/bg-sky.jpg') top center no-repeat;
}
</style>
