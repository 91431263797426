<template>
  <main>
    <b-container class="ombre">
      <h2 v-html="$t('banners.title')"></h2>
      <p v-html="$t('banners.text')"></p>
      <h3 v-html="$t('banners.dio')"></h3>
      <h4 class="text-center">
        180x240
      </h4>
      <b-row class="s180x240">
        <b-col
          v-for="img in ['aaronix', 'bourrepifix', 'carte', 'cyperpolicus', 'stallmanix']"
          :key="img"
          md="4"
        >
          <img
            :src="`${$t('baseurl')}img/banners/badge_${img}_gee_cc-by-sa.png`"
            alt=""
          />
          <pre v-text="sharedCode(`badge_${img}_gee_cc-by-sa.png`)"></pre>
        </b-col>
      </b-row>

      <h4 class="text-center">
        728x90
      </h4>
      <b-row class="s728x90">
        <b-col
          v-for="img in ['aaronix', 'bourrepifix', 'cyberpolicus', 'stallmanix']"
          :key="img"
          cols="12"
        >
          <img
            :src="`${$t('baseurl')}img/banners/banner_${img}_gee_cc-by-sa.png`"
            alt=""
          />
          <pre v-text="sharedCode(`banner_${img}_gee_cc-by-sa.png`)"></pre>
        </b-col>
      </b-row>

      <dl class="signature">
        <dt v-html="$t('banners.credits')"></dt>
        <dd>
          <a href="https://ptilouk.net">{{ $t('people.gee') }}</a> -
          <span v-html="$t('banners.license')"></span>
          <span v-html="$t('license.ccbysa4fr')"></span>
        </dd>
      </dl>

      <h3 v-html="$t('banners.old')"></h3>
      <h4 class="text-center">
        180x240
      </h4>
      <b-row class="s180x240">
        <b-col md="4">
          <img
            :src="`${$t('baseurl')}img/banners/badge_degooglisons_2013_lldemars_art-libre.png`"
            alt=""
          />
          <pre v-text="sharedCode(`badge_degooglisons_2013_lldemars_art-libre.png`)"></pre>
        </b-col>
        <b-col md="4">
          <img
            :src="`${$t('baseurl')}img/banners/badge_route_gee_cc-by-sa.jpg`"
            alt=""
          />
          <pre v-text="sharedCode(`badge_route_gee_cc-by-sa.jpg`)"></pre>
        </b-col>
      </b-row>

      <dl class="signature">
        <dt v-html="$t('banners.credits')"></dt>
        <dd>
          <a href="http://www.le-terrier.net/pingouin/pingouin.html">L.L. de Mars</a> -
          <span v-html="$t('banners.license')"></span>
          <span v-html="$t('license.lal')"></span><br />
          <a
            href="https://ptilouk.net"
            v-text="$t('people.gee')"
          ></a> -
          <span v-html="$t('banners.license')"></span>
          <span v-html="$t('license.ccbysa4fr')"></span>
        </dd>
      </dl>

      <h4 class="text-center">
        600x148
      </h4>
      <b-row class="s600x148">
        <b-col cols="12">
          <img
            :src="`${$t('baseurl')}img/banners/banner_merci_harrypopof_art-libre.jpg`"
            alt=""
          />
          <pre v-text="sharedCode(`banner_merci_harrypopof_art-libre.jpg`)"></pre>
        </b-col>
        <b-col cols="12">
          <img
            :src="`${$t('baseurl')}img/banners/banner_nojhan_cc-by-sa.png`"
            alt=""
          />
          <pre v-text="sharedCode(`banner_nojhan_cc-by-sa.png`)"></pre>
        </b-col>
        <b-col cols="12">
          <img
            :src="`${$t('baseurl')}img/banners/banner_route_gee_cc-by-sa.jpg`"
            alt=""
          />
          <pre v-text="sharedCode(`banner_route_gee_cc-by-sa.jpg`)"></pre>
        </b-col>
      </b-row>

      <dl class="signature ">
        <dt v-html="$t('banners.credits')"></dt>
        <dd>
          <a href="http://hpfteam.free.fr/graphisme-libre/">Harrypopof</a> -
          <span v-html="$t('banners.license')"></span>
          <span v-html="$t('license.lal')"></span><br />
          <a href="http://nojhan.free.fr/art/">NoJhan</a> -
          <span v-html="$t('banners.license')"></span>
          <span v-html="$t('license.ccbysa2fr')"></span><br />
          <a
            href="https://ptilouk.net"
            v-text="$t('people.gee')"
          ></a> -
          <span v-html="$t('banners.license')"></span>
          <span v-html="$t('license.ccbysa4fr')"></span>
        </dd>
      </dl>

      <h3 v-html="$t('banners.contrib')"></h3>
      <p v-html="$t('banners.contact')"></p>
    </b-container>
  </main>
</template>

<script>
export default {
  methods: {
    sharedCode(file) {
      return `<a href="${this.$t('link.soutenir')}" title="${this.$t('merci.share')}">
  <img alt="${this.$t('meta.title')}" src="${this.$t('link.soutenir')}/img/banners/${file}" />
</a>`;
    },
  },
};
</script>

<style lang="scss">
#banners {
  pre {
    border-radius: 0;
  }

  main img {
    margin: 0 auto;
    display: block;
    border: 1px solid #ccc;
  }

  .s728x90,
  .s600x148 {
    pre {
      margin: 0px auto 20px;
    }
  }

  .s728x90 pre {
    max-width: 730px;
  }

  .s600x148 pre {
    max-width: 602px;
  }
}
</style>
