<template>
  <table
    :class="`table table-responsive table-striped
      table-condensed table-hover table-donators`"
  >
    <caption
      class="sr-only"
      v-html="$t('stats.list')"
    ></caption>
    <thead>
      <tr>
        <th
          scope="col"
          v-html="$t('stats.th[0]')"
        ></th>
        <th
          class="text-center"
          scope="col"
          v-html="$t('stats.th[1]')"
        ></th>
        <th scope="col">
          <span
            class="sr-only"
            v-html="$t('stats.th[2]')"
          ></span>
        </th>
        <th scope="col">
          <i class="far user"></i>
          <span
            class="sr-only"
            v-html="$t('stats.th[3]')"
          ></span>
        </th>
        <th
          scope="col"
          v-html="$t('stats.th[4]')"
        ></th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(donator, index) in list"
        :key="index"
      >
        <td>
          <span v-text="donator.date"></span>
        </td>
        <td class="text-right">
          <span v-text="$n(donator.don, 'eur')"></span>
        </td>
        <td
          v-if="donator.monthly"
          class="text-center"
          :title="$t('stats.th[2]')"
        >
          <i class="fac fa-hand-holding-repeat"></i>
          <span
            class="sr-only"
            v-html="$t('stats.th[2]')"
          ></span>
        </td>
        <td
          v-else
          class="text-center"
        >
          <span
            v-if="donator.campaign && /peertube/.test(donator.campaign)"
            title="PeerTube"
          >
            <i class="fac fa-peertube"></i>
            <span class="sr-only">PeerTube</span>
          </span>
          <span
            v-if="donator.campaign && /mobilizon/.test(donator.campaign)"
            title="Mobilizon"
          >
            <i class="fac fa-mobilizon"></i>
            <span class="sr-only">Mobilizon</span>
          </span>
        </td>
        <td>
          <span v-text="donator.id"></span>
        </td>
        <td>
          <span v-text="donator.city"></span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>

export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
};
</script>
