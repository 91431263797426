<template>
  <main>
    <section
      class="illustration py-5"
    >
      <b-container>
        <b-row class="m-0 mb-5">
          <b-col class="text-center p-0">
            <h1 class="text-center mt-4 mb-5">
              <strong>Livre d’or – Témoignages</strong>
            </h1>
          </b-col>
        </b-row>
        <b-row
          align-h="center"
          class="mb-5"
        >
          <b-col
            lg="8"
            xl="9"
          >
            <blockquote class="mb-4">
              <div v-html="$t(msg[active][1], '-t')"></div>
              <p>
                <b
                  class="signature"
                  v-html="$t(msg[active][0], '-t')"
                ></b>
              </p>
            </blockquote>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section>
      <b-container
        id="page"
        ref="page"
        class="py-5 mt-n5"
      >
        <span
          v-for="n in Math.floor(msg.length / 10) + 1"
          :id="`page${n}`"
          :key="n"
        ></span>
        <b-row
          align-h="center"
          class="mb-5"
        >
          <b-col
            lg="8"
            xl="9"
          >
            <div
              v-for="n in 10"
              :key="n"
              class="msg"
            >
              <blockquote
                v-if="msg[n - 1 + (page - 1) * 10]"
                class="my-4"
              >
                <div v-html="$t(msg[n - 1 + (page - 1) * 10][1], '-t')"></div>
                <p>
                  <b
                    class="signature"
                    v-html="$t(msg[n - 1 + (page - 1) * 10][0], '-t')"
                  ></b>
                </p>
              </blockquote>
            </div>
            <b-pagination-nav
              v-model="page"
              align="fill"
              base-url="#page"
              label-first-page="Aller à la première page"
              label-last-page="Aller à la dernière page"
              label-next-page="Page suivante"
              label-prev-page="Page précédente"
              :number-of-pages="Math.floor(msg.length / 10) + 1"
            />
          </b-col>
        </b-row>
      </b-container>
    </section>
  </main>
</template>

<script>
/* eslint no-irregular-whitespace: 0 */
export default {
  data() {
    const msg = [
      [
        'Joss',
        'Merci pour cette recherche ancienne de liberté et de respect de notre vie privée, autant que d’applications de qualité.',
      ],
      [
        'Gentiana',
        `Bonjour à tous et toutes les free-istes!!
        Quel soulagement de savoir qu’il existe, sur la toile occupée, un petit village qui résiste…`,
      ],
      [
        'Nicole M',
        `Merci à vous toutes et tous qui nous aidaient à organiser régulièrement notre vie associative de manière si désintéressée.
        J’utilise FRAMADATE pour les plannings de plusieurs associations de chorale, rando, etc et c’est génial !`,
      ],
      [
        'nico54',
        'Excellent! Bien moins lourd que Doodle que j’aimais avant, mais qui est devenu compliqué et invasif.',
      ],
      [
        'Alinux',
        `Framasoft est pour moi la résurgence de l’internet du début, forums d’échanges et d’entraides. L’infernet d’aujourd’hui a été nourri aux engrais de l’hyper capitalisme et fait des ravages équivalents à ceux de l’agriculture intensive …
        Apprenons à nos enfants à développer leur sens critique.`,
      ],
      [
        'lixette',
        `La route est longue, mais grâce à vous la voie est libre et un peu moins sinueuse!
        Merci :) !`,
      ],
      [
        'Julien',
        `Framasoft m’a ouvert les yeux sur les enjeux du numérique et me donne envie de croire en un monde où l’humain passe avant les profits de la Big Tech.
        Quoi ? Un rêve d’enfant ?
        Si vous saviez quelles belles choses j’ai réalisées quand j’étais gamin, et j’en suis encore un aujourd’hui  :P`,
      ],
      [
        'JMS64',
        'Longue vie à Framasoft et merci pour tous les services disponibles !',
      ],
      [
        'gilou44',
        `Merci pour ce que vous faites.
        En tant qu’enseignant en SNT et technologie au lycée, je constate tous les jours le manque de connaissances et de recul des élèves (et de la plupart des parents) vis à vis des technologies numériques, que ce soit sur leur fonctionnement ou sur leurs dangers potentiels. Promouvoir le libre est fondamental pour sortir de la domination des quelques entreprises privées sur un domaine si fondamental.`,
      ],
      [
        'Fred',
        `Bonsoir à toute l’équipe !
        Un très, très grand merci pour votre action dans la durée, vous apportez une vision qui fait du bien au cerveau et des outils que j’utilise en confiance régulièrement.
        Vous occupez une place de choix et j’essaye de promouvoir votre expertise le plus souvent possible !
        Encore merci
        Fred`,
      ],
      [
        'LN',
        'Une bouffée d’oxygène dans un monde de g……gle ! Merci pour votre persévérance :)',
      ],
      [
        'Gryphius',
        'Des années que je suis votre travail et vos services… merci à tous ! Vous participez à rendre le monde plus libre et nous en avons tous chaque jour un peu plus besoin.',
      ],
      [
        'Manu C',
        `Des services magnifiquement bien pensés et toujours des améliorations.
        Continuez car la communauté a besoin de vous.
        Pour ma part je fais votre pub en permanence.
        MERCI`,
      ],
      [
        'pikerez',
        'z etes trop bien',
      ],
      [
        'Lhdv',
        'Merci pour tous les outils que vous mettez à disposition. Ils sont très « pro » et gratuit ! Incroyable mais vrai ! Pour les associations à petit budget où je suis bénévole, c’est précieux. Que c’est pratique de faire un sondage pour choisir une date de réunion, savoir qui vient à une réunion…',
      ],
      [
        'FranckP',
        `Bonjour !
        Un énorme BRAVO et MERCI à toute l’équipe de Framasoft ! Les outils que vous mettez à notre disposition sont formidables. C’est tellement bon de pouvoir se passer des GAFAM !

        Encore merci !`,
      ],
      [
        'virginie',
        `J’organise toutes mes planifications de réunions avec framadate.
        J’adore.
        Merci.
        Virginie`,
      ],
      [
        'Albessard Olivier',
        'J’utilise énormément WiseMapping…',
      ],
      [
        'Yann974',
        `Les outils numériques que vous proposez sont extrêmement utiles, faciles d’accès et respectueux des données personnelles. Merci, c’est une aide très appréciables pour l’ensemble de mes actions, tant professionnelles que personnelles.
        On sent le souci du détail, du respect de l’éthique.
        Merci aussi de proposer de nous éclairer, de nous informer, de poser le débat et de le documenter, sur le vaste sujet du « numérique », si présent dans nos vies.`,
      ],
      [
        'Fabio NG',
        'Maraîcher bio, je suis utilisateur de framaforms depuis quelques années. C’est à travers cet outil que je mets à jour, toutes les semaines, la liste de légumes disponibles pour qu’ensuite mes clients puissent les réserver. Globalement nous sommes très content de cette solution. Merci pour votre engagement, temps et travail ! ',
      ],
      [
        'Vincent pour le PUPES 64.4',
        `Framasoft accompagne par ses outils (Framalistes, Framadates) le Parlement d’Union Populaire de la 4ème circonscription des Pyrénées Atlantiques (64) depuis sa création en Juillet 2022. Cela aide à communiquer et organiser nos actions dans le respect de la vie privé des membres. Notre petite contribution est une manière de vous remercier.
        Merci !`,
      ],
      [
        'Hardgameurs',
        'J’ai vidé tout ce qui reste de mon compte paypal pour votre cause. Pas grand chose mais au moins cette argent va mieux servir chez vous !',
      ],
      [
        'Guitch',
        `Vous faites un taf de ouf qui permet à toustes de faire des trucs de dingue !
          Merci et Viva la folaille ;)`,
      ],
      [
        'C. Drozo',
        'Un grand merci à la communauté Framasoft pour cette belle suite efficace de logiciels libres! Un geste nécessaire pour le bien commun informationnel.',
      ],
      [
        'Jeremy',
        'Framasoft est une alternative éthique solide et efficace, un monde meilleur est possible, iels en sont une preuve, merci à vous !',
      ],
      [
        'Antoine ADAM',
        'Merci pour ces services utilisés au quotidien de mon côté et surtout pour toutes les actions d’éducation populaire que vous faites !',
      ],
      [
        'fabBlab',
        'Cela fait de nombreuses années que je suis les aventures de Framasoft. En fait depuis temps où il s’agissait essentiellement d’un annuaire de logiciels libres… Heureux de voir les évolutions, bien que passant moins de temps pour lire le blog, etc. Heureux aussi de pouvoir soutenir avec mes deniers. La route est longue, mais la voie est libre !',
      ],
      [
        'Florent G',
        'Merci pour faire avancer l’éthique et la solidarité :)',
      ],
      [
        'Le Maillon',
        'Juste merci pour votre travail et vos outils !!',
      ],
      [
        'Iliav Rez',
        `Framasoft a permis au commandant de bord d’une ancienne de discussion supprimée presque sans préavis de créer une nouvelle liste, sorte de grande chaloupe de sauvetage, devenue nouveau navire et qui fonctionne fort bien.
        Donc, merci !`,
      ],
      [
        'Bruno G',
        'Continuons à rester « différents »',
      ],
      [
        'Guy HOLLIER LAROUSSE',
        `Vous êtes un rayon de lumière dans le ciel ténébreux des GAFAM.
        continuez, prospérez 👍
        Guy HOLLIER LAROUSSE
        Président fondateur et inventeur de PIAMOT`,
      ],
      [
        'Guillaume Davranche',
        'Merci pour tout votre boulot pour un internet libre !',
      ],
      [
        'Gaou',
        `Il faut soutenir le logiciel libre pour protéger nos données personnelles.
        Bravo à vous !`,
      ],
      [
        'Pomme80',
        `Année après année, j’apprécie et mesure le travail d’éducation populaire réalisé par Frama. Merci.
        Dans une société de plus en plus dure ou le repli sur soi menace, Framasoft est mon rayon de soleil !`,
      ],
      [
        'Dom',
        'Je vous adore car vous êtes indispensables. Nous construisons ensemble un autre monde, celui de l’entraide (coucou Pablo Servigne).',
      ],
      [
        'Pierre',
        `grâce à vous on arrive à sortir de Google et autres GAFAM
        merci`,
      ],
      [
        'amap Le Pallet',
        `C’est au nom de notre Amap du Pallet dans le 44 au sud de Nantes que nous participons à cette cagnotte. Vos outils nous permettent en tant qu’association d’être cohérent avec nos valeurs.
        Merci à vous pour notre framalist, les framacalc réguliers sans parler des framadates quasi mensuel.
        Bravo pour votre engagement !`,
      ],
      [
        'Aurelifasolasido',
        `Pour moi framasoft c’est de l’espoir ! mais aussi du confort, du pratique et du collaboratif !

        MERCI de nous bichonner :-)`,
      ],
      [
        'Pierre',
        'Tout mon soutien à ce magnifique projet pour l’avenir en commun.',
      ],
      [
        'jfc',
        'Framasoft : ils sont bien !',
      ],
      [
        'nadjanadj',
        `bravo pour votre travail.
        je n’utilise pas frama au quotidien car sous licence Microsoft par le taf. mais je trouve indispensable d’avoir l’alternative.
        MERCI`,
      ],
      [
        'Barbara',
        `Merci pour tous les services que framasoft via framaform m’a rendu dans l’exercice de mon engagement associatif.
        Merci de ne pas avoir fermé le service pendant les grèves
        Bonne continuation`,
      ],
      [
        'Permacultopia',
        'Menacée comme jamais, au-delà de toutes autres considérations, la liberté n’a pas de prix !',
      ],
      [
        'Franck Trapletti',
        `Je vous remercie pour tout ce que vous faite pour nous.
        C’est trop top !
        Bonne continuation.
        franck.`,
      ],
      [
        'Roberto Scotti',
        `Merci a vous!
        It is of great help to know that, beyond evidencing that our digital world has serious problems, there a groups developing solutions!
        Roberto`,
      ],
      [
        'Oli81 (GNSA-VNE)',
        'Vous faites un travail formidable et c’est vraiment essentiel de pouvoir utiliser d’autres outils que Google, Microsoft et compagnie !',
      ],
      [
        'JMarie',
        `Un témoignage ? Par votre existence et vos attachements vous témoignez que la liberté est possible pourvu que l’on s’en donne les moyens !
        Nos servitudes sont hélas souvent que trop volontaires par défaut de penser.
        Merci à vous, continuez !`,
      ],
      [
        'Ced29',
        'Framasoft : une bouffée d’air frais dans un monde numérique à la botte de la Big Tech. Merciiiiiiii !',
      ],
      [
        'crapaudHPB',
        'Un coup de pouce… vers le haut',
      ],
      [
        'Brochon',
        'Bravo ! Continuez !!!',
      ],
      [
        'ArDu',
        'Je n’utilise personnellement pas les services de Framasoft puisque je n’en ai pas besoin, et je préfère les hoster moi même. Mais pour tous ceux qui ne peuvent pas faire ça, je sais combien de temps ça prend à des gens, combien d’argent ça coûte, combien d’électricité ça consomme.',
      ],
      [
        'mafouj',
        'Merci infiniment pour tous vos services et pour l’ambiance. Je viens d’apprendre que vous étiez une émanation de l’UTC. Cela ne m’a pas étonnée. Bravo à vous tous.',
      ],
      [
        'JNow',
        'Merci à toute l’équipe de Framasoft pour son travail et son offre de services de qualité qui permet de soutenir le fonctionnement d’associations citoyennes comme la nôtre (bec01.fr).',
      ],
      [
        'Oly',
        `Merci pour tous ces outils open source que vous proposez.
        J’utilise tout particulièrement framaforms et framadate. Framaforms est très performant pour les évaluations de formation (je suis formatrice), mais aussi pour les questionnaires de préparation de conseil de classe (je suis aussi parent délégué), beaucoup plus performant que google forms en tous cas!`,
      ],
      [
        'Cesar Brutus',
        'Vous êtes une de nos rares chances, trop peu comprise, d’échapper à l’Orwellisation de nos sociétés',
      ],
      [
        'GD56',
        `Je suis très heureux de contribuer, modestement, à votre œuvre et je souhaite que vous puissiez un jour hausser votre performance au meilleur niveau pour devenir incontournables ET citoyens.
        Pour ma part, je ressens la plus grande sérénité et la meilleure confiance lorsque je passe par vos outils.`,
      ],
      [
        'Marielle',
        `Merci à toutes les équipes et collaborateurs de Framasoft pour le travail effectué, pour l’énergie et la créativité, pour le partage et le respect.
        Que ce beau projet perdure et se développe au profit de tous !`,
      ],
      [
        'passant',
        `J’ai donné 25 euros parce que j’aime bien ce que vous faites et que je suis souvent utilisateur !
        Sinon bon courage et ne vous dispersez par trop, il vaut mieux peu de services excellents que des tonnes de services médiocres ! Enfin je crois.`,
      ],
      [
        'Olivier',
        `Framasoft est une association utile et efficace qui offre de nombreux services. Elle ne vit que des dons, donc n’hésitez pas à participer, ne serait-ce que pour quelques euros si vous utilisez ses services.
        Merci Framasoft.`,
      ],
      [
        'Tharqua',
        'l’une des meilleures initiatives web que je connaisse et des services super pratiques !',
      ],
      [
        'Damien.M (24)',
        'Merci pour tout ce que vous faîtes <3',
      ],
      [
        'potatoes001',
        'Des services gratuits de qualité, dans un état d’esprit ouvert, créatif, honnête et très sympathique !',
      ],
      [
        'AnneSo',
        'Utilisatrice régulière des outils libres dont vous faites le développement et/ou la promotion, je vous remercie infiniment pour votre taff, pour la manière inspirante dont vous l’exercer ! Que du love !! merci',
      ],
      [
        'Mike',
        'Je suis heureux d’aider cette superbe entreprise humaine et solidaire à soutenir une autre vision du numérique et toutes ces personnes qui travaillent pour un monde dont je partage toutes les valeurs. Framasoft est la preuve qu’un autre monde est possible ensemble.',
      ],
      [
        'vanspranghe francis',
        'Merci de me délivrer de google et de tous ceux qui nous préparent un avenir douteux avec la 5G et 6G…',
      ],
      [
        'Notabene',
        `Framasoft c’est bien.

        Voilà.

        Et sinon, c’est un moyen de s’émanciper des acteurs d’un internet privateur (exactement comme on parle de logiciels privateurs), où le plus gros du travail est déjà fait par des gens gentils et dévoués.
        Grâce à vous non seulement je ne suis plus jamais obligé de passer par les GAFAM, mais en plus j’apprends des trucs et je me mets à faire tout seul en suivant votre exemple.

        Bref, un don mensuel pour utiliser vos services et soutenir votre action, ça me semble tout à fait légitime !`,
      ],
      [
        'DJ',
        `Utisant le 10 eme de vos ressources , j’apprécie notamment les FRAMADATE s et les PAD, les cloud s.
        Sans publicité et effacés systématiquement au bout d’un délai raisonnable; ce qui nous laisse la tête libre pour travailler ; sans subir les nombreux rappels à passer à un mode payant pour cause de saturation de nos « cloud »
        Merci à FRAMASOFT d’exister.`,
      ],
      [
        'Lucky',
        'Tout simplement bravo, pour votre combat pour un internet libre et respectueux !',
      ],
      [
        'Aucun',
        `Merci à tous ceux qui font Framasoft.
        Utilisateur régulier de Framadate, j’en suis très satisfait.`,
      ],
      [
        'Gregg',
        `Une trouvaille Framasoft que je ne connaissais pas du tout.
        J’ai pu travailler conjointement sur un texte en cours d’élaboration avec Framapad. Un outil juste excellent. On peut modifier le texte, voir qui a fait quoi, faire des propositions etc.
        Que de temps de gagné.
        Je pense que je vais revoir mon utilisation de certains outils pour passer sur les alternatives de Framasoft !`,
      ],
      [
        'georgesk',
        'des espaces sur framagit, et sur framapad',
      ],
      [
        'Sergio',
        `Soyons lucides, même le gratuit a un coût.
        Donnons un peu, la liberté a un prix !`,
      ],
      [
        'Paul Barnes',
        'Merci à tous les bénévoles de Framasoft qui nous apportent de multiples solutions et outils simples, sympas, et originaux, qui nous permettent d’échapper aussi à la marchandisation globale, et d’assurer une meilleure protection de nos données personnelles !',
      ],
      [
        'bcql',
        `J’utilise fréquemment les outils framadate, liste ou pad. Ils me sont bien utiles dans le cadre personnel ou associatif, et sont une alternative solide aux outils des méga-compagnies.
        Je trouve naturel de contribuer par un petit financement au bon fonctionnement de cette plateforme.`,
      ],
      [
        'Nanie',
        `Un grand merci aux bénévoles qui font tourner les outils libres de Framasoft.
        Une preuve que tout n’est pas mercantile.`,
      ],
      [
        'Gabriel',
        'Framasoft contribue à construire des ressources et outils qui seront essentiels pour l’avenir. Soutenez-les !',
      ],
      [
        'eti95',
        'Framasoft garantit l’efficacité du travail en groupe et la discrétion des échanges.',
      ],
      [
        'Deuxnielle',
        `Vous le valez bien !
        Continuez 😉`,
      ],
      [
        'luimeme',
        'Framasoft approrte de la JOIE !',
      ],
      [
        'Bougl',
        `Et alors, Framasoft, où sont les liens Mastodon pour le partage du soutien ???
        Bonne chance et à bientôt.`,
      ],
      [
        'el-crouni',
        'Bravo et merci pour le merveilleux travail que vous accomplissez. On continue !',
      ],
      [
        'gilpeo',
        'énorme bravo à toustes ! comme toujours !',
      ],
      [
        'Yann',
        `Bonjour, dans notre association, nous commençons tout juste à utiliser Framateam. Donc pas encore assez d’expérience pour vous faire de retour.
        Mais, bravo pour votre travail !
        Et d’oeuvrer à proposer des solutions alternatives sur internet. Continuez bien !`,
      ],
      [
        'Buck',
        'Bravo Framasoft pour ce que vous faites !',
      ],
      [
        'jjango',
        'ils sont les Gafam, vous êtes les Gars sûrs',
      ],
      [
        'Remuald85',
        'Merci pour l’attention que vous portez à rendre des services Internet accessibles et démocratiques. Continuez l’aventure vers un monde plus ouvert.',
      ],
      [
        'Kris',
        `Merci à Framasoft de nous proposer ces services si utiles au quotidien pour fonctionner en groupe à distance : les pads pour les réunions, les framalistes, les framaforms ! On a tellement besoin de ça, de ces outils qui ne nous pistent pas, cherchent juste à être utiles sans vouloir capter du temps de cerveau, ni de l’argent. Merci aussi pour vos contributions à d’autres services, les efforts faits pour nous informer, et nous permettre de cheminer vers d’autres usages, pour vos réflexions partagées, et pour l’accompagnement que vous apportez aux collectifs pour sortir du tout Google.
        Merci pour votre engagement, et merci de toutes ces initiatives pour un numérique plus éthique, plus émancipateur et plus libre, loin du monopole et du fonctionnement capitaliste. Merci de vos propositions pour réfléchir le monde autrement (collecte et confiance, édition de livres libres…).
        Vous assurez grave !`,
      ],
      [
        'KNel',
        'Merci pour tout votre travail et vos outils qui permettent de sortir peu à peu des « concurrents » soit disant gratuit',
      ],
      [
        'Germain57',
        'Merci à l’équipe de Framasoft. Longue vie et réussite dans vos projets. Merci.',
      ],
      [
        'François L',
        `Super
        Toujours un grand plaisir d’utiliser FramaTalk`,
      ],
      [
        'RECIT',
        'Nous animons un réseau d’une centaine de structures qui oeuvre pour produire une énergie renouvelable et citoyenne. Nos porteurs de projet sont essentiellement des bénévoles et les différents outils de framasoft leurs permettent de mener à bien leur projets collectifs et exemplaires.',
      ],
    ];
    return {
      active: Math.floor(Math.random() * msg.length),
      animate: true,
      msg,
      page: /^page/.test(this.$t('hash')) ? this.$t('hash').replace(/^page/, '') : 1,
    };
  },

  created() {
    const animation = setInterval(() => {
      if (this.animate) {
        if (this.active + 1 < this.msg.length - 1) {
          this.active += 1;
        } else {
          this.active = 1;
          this.animate = false;
        }
      } else {
        clearInterval(animation);
      }
    }, 15000);
  },
};
</script>

<style lang="scss">
#guestbook {
  background: #50386e url('../../public/img/bg-sky.jpg') no-repeat 50% !important;
  background-size: cover;
  display: table;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  > * {
    position: relative;
    background: transparent !important;
  }

  .illustration {
    background: url('../../public/img/guestbook.png') top center no-repeat;
    background-size: cover;

    .container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 50rem;
    }

    h1 {
      color: #fff;
      display: inline-block;
      font-family: "Tovari Sans", sans-serif;
      font-weight: bold;
      margin: 1rem auto 25rem auto;
      max-width: 400px;
      position: relative;
      text-align: center;
      text-transform: uppercase;
      text-shadow: none;

      strong {
        display: inline-block;
        border-radius: 0.5rem;
        background: var(--f-o6);
        font-size: 3rem;
        line-height: 2.75rem;
        padding: 1.25rem 1.75rem 0.5rem;
        transform: rotate(-2deg);
      }
    }

    blockquote {
      font-size: 150%;
    }
  }

  .page-item {
    &.disabled {
      visibility: hidden;
    }

    .page-link {
      background: var(--f-g1);
      border-radius: 50rem!important;
      margin-left: .25rem;
      line-height: 1;
    }

    &.active .page-link {
      background: var(--f-f7);
    }
  }

  .signature {
    color: var(--f-o8);

  }

  #page {
    background: var(--f-g2);
    border-radius: 1.75rem;
    padding: 3rem;
    margin: 5rem auto;

    blockquote {
      margin-bottom: 2rem;

      &::before {
        content: none;
        background: none;
      }
    }

    .msg:nth-of-type(2n+1) {
      blockquote {
        border-left-color: var(--f-f5) !important;
      }

      .signature {
        color: var(--f-f7);
      }
    }
  }
}
</style>
