<template>
  <div class="home">
    <intro />

    <roster />

    <why />
    <!-- <section class="quote pt-4">
      <b-container class="mb-0">
        <b-row>
          <b-col>
            <blockquote class="blockquote text-right">
              <span v-html="$t('c2022.why.list[0]')"></span>
              <footer class="blockquote-footer">
                <a
                  :href="$t('lang') === 'fr'
                    ? $t('link.fr.collectivisons')
                    : $t('link.en.collectivisons')"
                  v-html="$t('c2022.why.blog')"
                ></a>
              </footer>
            </blockquote>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section class="quote pt-4">
      <b-container class="mb-0">
        <b-row>
          <b-col>
            <blockquote class="blockquote text-right">
              <span v-html="$t('c2022.why.list[1]')"></span>
              <footer class="blockquote-footer">
                <a
                  :href="$t('lang') === 'fr'
                    ? $t('link.fr.convivialisons')
                    : $t('link.en.convivialisons')"
                  v-html="$t('c2022.why.blog')"
                ></a>
              </footer>
            </blockquote>
          </b-col>
        </b-row>
      </b-container>
    </section> -->
  </div>
</template>

<script>
import intro from './campaign/2023/Intro.vue';
import roster from './campaign/2023/Roster.vue';
import why from './campaign/2023/Why.vue';

export default {
  components: {
    intro,
    roster,
    why,
  },
};
</script>

<style lang="scss">
#home.cambpaign {
  overflow-x: hidden; /* monsters too big on mobile */

  @media (min-width: 768px) {
    overflow-x: auto;
  }
}

#c2023-why, #progress-banner {
  h2, .h2 {
    color: var(--f-g10);
    font-family: 'Tovari Sans';
    font-size: 3rem;
    line-height: 2.5rem;
    margin-bottom: 1rem;
  }
}

#support, #lastyear {
  h2 {
    background: var(--f-o6);
    border-radius: .5rem;
    color: #fff;
    font-family: 'Tovari Sans';
    font-size: 3rem;
    font-style: normal;
    line-height: 2.5rem;
    padding: 1.25rem 1.75rem .5rem;
    text-align: center;
    text-transform: uppercase;
    transform: rotate(-2deg);
    width: fit-content;

    margin: 0 auto 2rem 0;
  }

  h3 {
    color: var(--f-g10);
  }
}

#actions {
  h1 {
    background: var(--f-o6);
    border-radius: .5rem;
    color: #fff;
    font-family: 'Tovari Sans';
    font-size: 3rem;
    font-style: normal;
    line-height: 2.5rem;
    padding: 1.25rem 1.75rem .5rem;
    text-align: center;
    text-transform: uppercase;
    transform: rotate(-2deg);
    width: fit-content;
    margin: -5rem auto 3rem 0 !important;
  }

  h2 {
    font-family: 'Tovari Sans';
    font-size: 3rem;
    line-height: 2.5rem;
    margin-bottom: 1rem;
    text-transform: none;
    transform: none;
  }
}

#lastyear {
  h2 {
    margin: -5rem auto 4rem 0;
  }

  h3 {
    color: var(--f-g10);
    font-family: 'Tovari Sans';
    font-size: 3rem;
    line-height: 2.5rem;
    margin-bottom: 1rem;
  }
}

#questions {
  h2 {
    font-family: 'Tovari Sans';
    font-size: 3rem;
    line-height: 2.5rem;
    margin-bottom: 1rem;
  }
}

/* Quote */
.quote {
  background: var(--f-g4);

  p {
    padding-top: 10px;
    text-align: left;
  }

  a {
    color: var(--f-g8);
    text-decoration: underline;
    background: none !important;

    &:hover, &:focus {
      color: var(--f-g9);
      text-decoration: underline;
    }
  }

  text-align: center;

  button.btn {
    font-size: 1.3em;
    width: 14em;
    word-break: break-word;
    white-space: normal;

    &::first-line {
      font-size: 1.5em;
    }
  }

  blockquote {
    padding: 0;
    max-width: 850px;
    margin-left: auto;
    background: transparent;
    border: none;

    &::before { display: none; }

    span {
      border-right: 0.2em solid var(--f-g8);
      position: relative;
      display: block;
      padding-right: 1em;
    }
  }
}
</style>
