var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"mt-2 d-flex flex-column"},[_c('div',{staticClass:"mt-5",attrs:{"id":"c2022-frama"}},[_c('b-container',{staticClass:"p-4 px-sm-0"},[_c('h2',{staticClass:"quack",staticStyle:{"display":"none"},domProps:{"innerHTML":_vm._s(_vm.$t('c2022.frama.ctitle'))}}),_vm._v(" "),_c('h2',{domProps:{"innerHTML":_vm._s(_vm.$t('c2022.frama.title'))}}),_vm._v(" "),_c('div',{staticClass:"col-lg-9 p-0 mb-n1",domProps:{"innerHTML":_vm._s(_vm.$t('c2022.frama.desc'))}})])],1),_vm._v(" "),_vm._l((Object.keys(_vm.sections)),function(section){return _c('div',{key:section,attrs:{"id":section}},[_c('b-container',{staticClass:"p-4 px-sm-0"},[_c('b-row',{staticClass:"cards mx-0"},_vm._l((_vm.sections[section].cards),function(key){return _c('b-col',{key:key,staticClass:"d-flex justify-content-center p-0",attrs:{"lg":"3","md":"6","cols":"12"}},[_c('div',{ref:`flip-card-${key}`,refInFor:true,staticClass:"flip-card",attrs:{"id":`flip-card-${key}`}},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"front"},[_c('div',{staticClass:"layer-bg-img"},[_c('div',{style:(`background-image: url('${_vm.$t('baseurl')
                    }img/campaign/2022/${key}.jpg');`)})]),_vm._v(" "),_c('div',{staticClass:"layer-frame"}),_vm._v(" "),_c('button',{staticClass:"layer-content",attrs:{"title":_vm.$t('c2022.flip', {
                    name: _vm.$t(`c2022.cards.${key}.name`)
                  })},on:{"click":function($event){return _vm.flipCard(`flip-card-${key}`)}}},[_c('h3',{domProps:{"innerHTML":_vm._s(_vm.$t(`c2022.cards.${key}.name`))}})])]),_vm._v(" "),_c('div',{staticClass:"back",on:{"click":function($event){return _vm.flipCard(`flip-card-${key}`)}}},[_c('h3',{attrs:{"aria-hidden":"true"},domProps:{"innerHTML":_vm._s(_vm.$t(`c2022.cards.${key}.name`))}}),_vm._v(" "),_c('div',{staticClass:"desc",domProps:{"innerHTML":_vm._s(_vm.$t(`c2022.cards.${key}.desc`))}})])])])])}),1)],1)],1)}),_vm._v(" "),_c('div',{style:(_vm.is.after('2022/11/29') ? 'order: 0; background: var(--f-g4)' : ''),attrs:{"id":"progress-banner","aria-hidden":"true"}},[_c('b-container',{staticClass:"text-center my-5"},[_c('p',{staticClass:"h2 quack",staticStyle:{"display":"none"},domProps:{"innerHTML":_vm._s(_vm.$t('c2022.progressbar.ctitle'))}}),_vm._v(" "),_c('p',{staticClass:"h2",domProps:{"innerHTML":_vm._s(_vm.$t('c2022.progressbar.title'))}}),_vm._v(" "),_c('p',[_c('a',{attrs:{"href":_vm.$t('lang') === 'fr'
            ? _vm.$t('link.fr.convivialisons')
            : _vm.$t('link.en.convivialisons')},domProps:{"innerHTML":_vm._s(_vm.$t('c2022.intention-blog'))}})])]),_vm._v(" "),_c('div',{staticClass:"banner"},[_c('div',{ref:"banner-active",staticClass:"active",style:(`width: calc(100% * ${_vm.donations.range} / ${_vm.donations.max});`)},[_c('div')]),_vm._v(" "),_vm._m(0)]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.donations.range),expression:"donations.range"}],staticClass:"slider",attrs:{"max":_vm.donations.max,"min":"1","type":"range"},domProps:{"value":(_vm.donations.range)},on:{"__r":function($event){return _vm.$set(_vm.donations, "range", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"text-right",style:((_vm.donations.range / _vm.donations.max) < 1
        ? `width: calc(100% * ${_vm.donations.range} / ${_vm.donations.max} + 35px)`
        : 'width: 100%')},[_c('b-badge',{staticStyle:{"width":"70px"},attrs:{"variant":"secondary"}},[_c('span',{domProps:{"innerHTML":_vm._s(`${_vm.donations.range}&nbsp;€`)}}),_c('br'),_vm._v(" "),_c('small',[_c('i',{domProps:{"innerHTML":_vm._s(`(${Math.min((_vm.donations.range / _vm.donations.max), 1)
              .toLocaleString(_vm.$t('lang'), { style: 'percent' })})`)}})])])],1)],1)],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inactive"},[_c('div')])
}]

export { render, staticRenderFns }