<template>
  <section class="mt-2 d-flex flex-column">
    <div
      class="sticky-top"
      style="order: -1; z-index: 999"
    >
      <b-container class="p-0">
        <b-btn
          class="float-right"
          :to="`/${$t('lang')}/#support`"
          variant="soutenir mt-2 mr-5 mr-sm-0"
        >
          <i class="fas fa-heart"></i>
          <span v-html="$t('campaign.donate')"></span>
        </b-btn>
        <b-btn
          aria-hidden="true"
          class="float-right"
          href="#"
          variant="outline-secondary mt-2 mr-2"
          :title="$t('campaign.flipall')"
          @click.prevent="flipAll"
        >
          <i class="fas fa-hat-wizard"></i>
        </b-btn>
      </b-container>
    </div>
    <div
      id="campaign-intro"
      class="mt-5 mt-sm-0"
      style="order: -1"
    >
      <b-container class="p-4 px-sm-0 mt-n5">
        <h2 v-html="$t('campaign.title')"></h2>
        <div
          class="col-lg-9 p-0 mb-n1 "
          v-html="$t('campaign.intro')"
        ></div>
      </b-container>
    </div>

    <div
      v-for="section in Object.keys(sections)"
      :id="section"
      :key="section"
      style="order: 1"
    >
      <b-container class="p-4 px-sm-0">
        <h3 v-html="$t(`campaign.sections.${section}.title`)"></h3>
        <div class="col-lg-9 p-0">
          <p v-html="$t(`campaign.sections.${section}.intro`)"></p>
          <p>
            <a
              :href="sections[section].blog"
              v-html="$t('campaign.desc-blog')"
            ></a>
          </p>
        </div>
        <b-row class="cards mx-0">
          <b-col
            v-for="key in sections[section].cards"
            :key="key"
            lg="3"
            md="6"
            cols="12"
            class="d-flex justify-content-center p-0"
          >
            <div
              :id="`flip-card-${key}`"
              :ref="`flip-card-${key}`"
              class="flip-card"
            >
              <div class="inner">
                <div class="front">
                  <div
                    class="layer-bg-img"
                  >
                    <div
                      :style="`background-image: url('${$t('baseurl')
                      }img/campaign/2021/${key}.jpg');`"
                    ></div>
                  </div>

                  <div class="layer-frame"></div>

                  <div class="layer-bg-btn">
                    <span
                      v-if="cards[key] && cards[key].info"
                      class="orb-info"
                    >
                      <i class="fas fa-info fa-shape-circle"></i>
                    </span>
                  </div>
                  <div class="layer-bg-btn">
                    <span
                      v-if="cards[key] && cards[key].link"
                      class="orb-link"
                    >
                      <i class="fas fa-link fa-shape-circle"></i>
                    </span>
                  </div>

                  <button
                    class="layer-content"
                    :title="$t('campaign.flip', {
                      name: $t(`campaign.cards.${key}.name`)
                    })"
                    @click="flipCard(`flip-card-${key}`)"
                  >
                    <h2 v-html="$t(`campaign.cards.${key}.name`)"></h2>
                  </button>

                  <div
                    v-if="cards[key] && cards[key].info"
                    class="layer-orb-info"
                  >
                    <a
                      class="btn btn-link orb-info"
                      :href="cards[key].info"
                      :title="$t('campaign.info.desc', {
                        name: $t(`campaign.cards.${key}.name`),
                      })"
                    >
                      <i class="fas fa-info fa-shape-circle"></i>
                      <span
                        class="sr-only"
                        v-html="$t('campaign.info.label')"
                      ></span>
                    </a>
                  </div>

                  <div
                    v-if="cards[key] && cards[key].link"
                    class="layer-orb-link"
                  >
                    <a
                      class="btn btn-link orb-link"
                      :href="cards[key].link"
                      :title="$t('campaign.link.desc', {
                        name: $t(`campaign.cards.${key}.name`),
                      })"
                    >
                      <i class="fas fa-link fa-shape-circle"></i>
                      <span
                        class="sr-only"
                        v-html="$t('campaign.link.label')"
                      ></span>
                    </a>
                  </div>
                </div>

                <div
                  class="back"
                  @click="flipCard(`flip-card-${key}`)"
                >
                  <h2
                    aria-hidden="true"
                    v-html="$t(`campaign.cards.${key}.name`)"
                  ></h2>
                  <p
                    class="desc"
                    v-html="$t(`campaign.cards.${key}.desc`)"
                  ></p>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <div
      id="progress-banner"
      aria-hidden="true"
      :style="is.after('2021/11/29') ? 'order: 0; background: var(--f-g4)' : ''"
    >
      <b-container class="text-center my-5">
        <p
          class="h2"
          v-html="$t('campaign.outro')"
        ></p>
        <p v-if="is.after('2021/12/06')">
          <a
            href="https://framablog.org/?p=25864"
            v-html="$t('campaign.intention-blog')"
          ></a>
        </p>
        <p v-else>
          <span v-html="$t('campaign.intention-blog')"></span>
          <i v-html="$t('campaign.soon')"></i>
        </p>
      </b-container>

      <div class="banner">
        <div
          ref="banner-active"
          class="active"
          :style="`width: calc(100% * ${donations.range} / ${donations.max});`"
        >
          <div></div>
        </div>
        <div class="inactive">
          <div></div>
        </div>
      </div>
      <input
        v-model="donations.range"
        class="slider"
        max="180000"
        min="1"
        type="range"
      />
      <div
        :style="(donations.range / donations.max) < 1
          ? `width: calc(100% * ${donations.range} / ${donations.max} + 35px)`
          : 'width: 100%'"
        class="text-right"
      >
        <b-badge
          style="width: 70px"
          variant="secondary"
        >
          <span v-html="`${donations.range}&nbsp;€`"></span>
        </b-badge>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      donations: {
        init: 114332, // at the beginning (2021/10/11)
        current: 0, // real stats (from 2021.json)
        range: 0, /* = current - init when json loaded
                    users can play with the slider to colourize the banner
                    so the value is reset periodically (cf created()) */
        max: 180000, // campaign target
      },
      sections: {
        humans: {
          blog: 'https://framablog.org/2021/10/12/frama-cest-aussi-des-humain%c2%b7es-qui-sassocient/',
          cards: ['inside', 'campaign', 'employees', 'fun'],
        },
        services: {
          blog: 'https://framablog.org/2021/10/19/frama-cest-aussi-des-services-en-ligne/',
          cards: ['date', 'pad', 'forms', 'dio'],
        },
        commons: {
          blog: 'https://framablog.org/2021/10/26/frama-cest-aussi-de-la-mediation-aux-communs-numeriques/',
          cards: ['conf', 'interview', 'blog', 'lang', 'dlec', 'upload'],
        },
        services2: {
          blog: 'https://framablog.org/2021/11/02/frama-cest-aussi-des-personnes-au-service-des-services/',
          cards: ['home', 'infra', 'support', 'alt'],
        },
        empowerment: {
          blog: 'https://framablog.org/2021/11/09/frama-cest-aussi-des-outils-pour-semanciper/',
          cards: ['mooc-chatons', 'resolu', 'metacartes', 'docs'],
        },
        archipelago: {
          blog: 'https://framablog.org/2021/11/16/frama-cest-aussi-des-contributions-dans-un-archipel/',
          cards: ['manifest', 'coalition', 'pytition', 'contribateliers',
            'chatons', 'benevalibre', 'mednum', 'misc'],
        },
        softwares: {
          blog: 'https://framablog.org/2021/11/23/frama-cest-aussi-des-logiciels-pour-liberer-les-usages/',
          cards: ['peertube', 'mobilizon', 'yakforms', 'contributions'],
        },
      },
      cards: {
        inside: {
          link: 'https://framasoft.org/fr/association/',
        },
        fun: {
          link: 'https://framaprout.org',
        },
        date: {
          info: 'https://docs.framasoft.org/fr/framadate/',
          link: 'https://framadate.org',
        },
        pad: {
          info: 'https://docs.framasoft.org/fr/etherpad/',
          link: 'https://framapad.org',
        },
        forms: {
          info: 'https://docs.framasoft.org/fr/framaforms/',
          link: 'https://framaforms.org',
        },
        dio: {
          link: 'https://degooglisons-internet.org',
        },
        conf: {
          link: 'https://wiki.framasoft.org/evenements',
        },
        interview: {
          link: 'https://wiki.framasoft.org/speakabout',
        },
        blog: {
          link: 'https://framablog.org',
        },
        lang: {
          link: 'https://participer.framasoft.org/fr/framalang/index.html',
        },
        support: {
          link: 'https://contact.framasoft.org',
        },
        alt: {
          info: 'https://framablog.org/2020/03/03/10-bonnes-raisons-de-fermer-certains-services-framasoft-la-5e-est-un-peu-bizarre/',
          link: 'https://alt.framasoft.org',
        },
        'mooc-chatons': {
          info: 'https://framablog.org/2020/02/26/ouverture-du-mooc-chatons-internet-pourquoi-et-comment-reprendre-le-controle/',
          link: 'https://mooc.chatons.org',
        },
        resolu: {
          info: 'https://framablog.org/2020/06/27/resolu-un-pas-de-plus-dans-contributopia/',
          link: 'https://soyezresolu.org',
        },
        metacartes: {
          info: 'https://framablog.org/2019/12/14/les-metacartes-numerique-ethique-un-outil-qui-fait-envie/',
          link: 'https://www.metacartes.cc/numerique-ethique/',
        },
        docs: {
          link: 'https://docs.framasoft.org',
        },
        coalition: {
          info: 'https://framablog.org/2019/11/22/une-coalition-francaise-pour-la-defense-et-la-promotion-de-lespace-democratique/',
          link: 'https://www.lacoalition.fr',
        },
        pytition: {
          info: 'https://framablog.org/2019/10/25/framapetitions-est-mort-vive-pytition/',
          link: 'https://github.com/pytition/Pytition',
        },
        contribateliers: {
          info: 'https://framablog.org/2019/12/27/les-contribateliers-contributopia-quand-on-ny-connait-rien/',
          link: 'https://contribateliers.org',
        },
        chatons: {
          info: 'https://framablog.org/2020/04/01/entraide-chatons-org-des-services-libres-en-ligne-sans-inscription/',
          link: 'https://chatons.org',
        },
        benevalibre: {
          info: 'https://framablog.org/2019/10/31/benevalibre-liberez-vos-benevoles-de-la-startupnation/',
          link: 'https://app.benevalibre.org',
        },
        mednum: {
          link: 'https://framablog.org/2020/09/15/annuaire-des-acteurs-et-actrices-de-laccompagnement-au-numerique-libre/',
        },
        misc: {
          info: 'https://framablog.org/2019/12/10/archipelisation-comment-framasoft-concoit-les-relations-quelle-tisse/',
          link: 'https://framasoft.org/fr/partenaires/',
        },
        peertube: {
          info: 'https://framablog.org/2021/01/07/peertube-v3-ca-part-en-live/',
          link: 'https://joinpeertube.org',
        },
        mobilizon: {
          info: 'https://framablog.org/2020/10/27/mobilizon-vos-evenements-vos-groupes-vos-donnees/',
          link: 'https://joinmobilizon.org',
        },
        yakforms: {
          info: 'https://framablog.org/2021/05/25/oubliez-framaforms-le-logiciel-faites-de-la-place-a-yakforms/',
          link: 'https://yakforms.org',
        },
        contributions: {
          link: 'https://wiki.framasoft.org/contributions',
        },
      },
    };
  },

  created() {
    document.body.classList.add('campaign');

    /* Reinit banner progressbar */
    setInterval(() => {
      if (this.donations.range !== (this.donations.current - this.donations.init)) {
        this.$refs['banner-active'].classList.add('animate');
        this.donations.range = this.donations.current - this.donations.init;
        setTimeout(() => {
          this.$refs['banner-active'].classList.remove('animate');
        }, 1000);
      }
    }, 5000);

    fetch('https://soutenir.framasoft.org/dons/2021.json', { cache: 'no-cache' })
      .then(res => res.json())
      .then((data) => {
        for (let i = 0; i < data.list.length; i += 1) {
          this.donations.current += Math.round(data.list[i].don);
        }
      })
      .catch((err) => { console.error(err) }); // eslint-disable-line
  },

  methods: {
    flipCard(id) {
      if (this.$refs[id][0].classList.contains('showback')) {
        this.$refs[id][0].classList.remove('showback');
        setTimeout(() => {
          this.$refs[id][0].classList.remove('inactive');
        }, 1000);
      } else {
        this.$refs[id][0].classList.add('showback');
      }

      const cards = Object.keys(this.$refs).filter(item => item !== id);
      cards.forEach((key) => {
        if (this.$refs[key][0]) {
          this.$refs[key][0].classList.remove('showback');
          if (this.$refs[id][0].classList.contains('showback')) {
            setTimeout(() => {
              this.$refs[id][0].classList.remove('inactive');
            }, 1000);
          }
        }
      });
    },

    flipAll() {
      const cards = Object.keys(this.$refs);
      cards.forEach((key) => {
        if (this.$refs[key][0]) {
          this.$refs[key][0].classList.toggle('showback');
          setTimeout(() => {
            this.$refs[key][0].classList.remove('inactive');
          }, 1000);
        }
      });
    },
  },
};
</script>

<style lang="scss">
#campaign2021.campaign {
  #f-sfs {
    #support {
      .col-lg-7 .ombre {
        margin-top: 3rem !important;
      }
    }
  }

  .sticky-top .btn-outline-secondary {
    background-color: var(--f-g2);
    border-color: transparent;

    &:hover, &:focus {
      background: var(--f-g7);
    }
  }

  /* Cards */
  .cards {
    list-style: none;
    padding: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;

    h2 {
      margin-top: 0px;
      margin-bottom: 12px;
      font-size:20px;
      font-weight: bold;
      text-align: center;

      &::after {
        content: none !important;
      }
    }

    .desc { // units in px (cards size is fixed)
      font-size: 15px;
      line-height: 21px;
      margin-top: 12px;
    }
  }

  .flip-card {
    background-color: transparent;
    width: 235px;
    height: 380px;
    perspective: 1000px;
    padding: 20px 0; /* keep shadow & card rotation visible */

    &.inactive .front {
      &::before {
        background: linear-gradient(to bottom, var(--f-m6), var(--f-m7));
        filter: grayscale(.85);
      }

      .layer-bg-img, .layer-frame,
      .layer-orb-info, .layer-orb-link {
        filter: grayscale(.75);
      }
    }

    .inner {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      transition: transform 0.8s;
      transform-style: preserve-3d;
    }

    // &:focus-within .inner,
    &.showback .inner {
      transform: rotateY(180deg);
    }

    .front, .back {
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden; /* Safari */
      backface-visibility: hidden;
      box-shadow: 0px 9px 15px -10px rgba(0, 0, 0, 0.7);
      border-radius: 4px;
    }

    .front {
      cursor: pointer;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom, var(--f-m7), var(--f-m8));
        border-radius: 4px;
      }

      > * {
        position: absolute;
      }

      .orb-info,
      .orb-link {
        position: absolute;
        font-size: 26px;
        background: none;
        padding: 0;

      }

      .orb-info {
        top: 4px;
        left: 4px;
      }

      .orb-link {
        bottom: 4px;
        right: 4px;
      }

      .layer-bg-img,
      .layer-frame,
      .layer-bg-btn,
      .layer-orb-info,
      .layer-orb-link,
      .layer-content {
        background-color: transparent;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: none;
        margin: 0;
        width: 100%;
        height: 100%;
      }

      .layer-bg-img {
        width: calc(100% - 30px);
        height: calc(100% - 145px);
        margin: 21px 15px;
        z-index: 0;
        transition: transform .5s ease-in-out;
        > div {
          border-radius: 20px 20px 0 0;
          width: 100%;
          height: 100%;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }

      &:hover {
        .layer-bg-img {
          transform:scale(1.05)
        }
      }

      .layer-frame {
        background-image: url('../../../../public/img/campaign/2021/card-frame.png');
        z-index: 1;
      }

      .layer-bg-btn {
        z-index: 2;

        .orb-info .fa-shape-circle {
          color: var(--f-b6);
        }

        .orb-link .fa-shape-circle {
          color: var(--f-f6);
        }
      }

      .layer-content {
        display: flex;
        padding: 0;
        z-index: 3;

        h2 {
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--f-m7);
          min-height: 74px;
          width: 100%;
          margin-bottom: 35px;
          margin-top: auto;
          padding: 0 30px;
          text-align: center;
          vertical-align: middle;
        }
      }

      .layer-orb-info,
      .layer-orb-link {
        z-index: 4;

        .fa-shape-circle {
          background: none;
          color: var(--f-m7);
          margin: -3px;
        }
      }

      .layer-orb-info {
        margin-top: 3px;
        margin-left: 3px;
        width: 64px;
        height: 72px;
        background-image: url('../../../../public/img/campaign/2021/orb-info.png');
      }
      .layer-orb-link {
        bottom: 0;
        right: 0;
        margin-bottom: 3px;
        margin-right: 3px;
        width: 64px;
        height: 66px;
        background-image: url('../../../../public/img/campaign/2021/orb-link.png');
      }
    }

    .back {
      background:
        var(--f-m4)
        url('../../../../public/img/campaign/2021/back.jpg')
        repeat
        center center;
      border: 6px solid var(--f-m6);
      color: var(--f-m7);
      cursor: pointer;
      overflow: hidden;
      padding: 1rem .75rem;
      transform: rotateY(180deg);
      z-index: 6;

      h2 {
        margin-bottom: 0;
      }
    }
  }

  #empowerment {
    .flip-card.inactive .front::before {
      background: linear-gradient(to bottom, var(--f-g6), var(--f-g7));
    }

    .front::before {
      background: linear-gradient(to bottom, var(--f-g7), var(--f-g8));
    }

    .back {
      border: 6px solid var(--f-g6);
    }
  }

  #services {
    .flip-card.inactive .front::before {
      background: linear-gradient(to bottom, var(--f-v5), var(--f-v6));
    }

    .front::before {
      background: linear-gradient(to bottom, var(--f-v6), var(--f-v7));
    }

    .back {
      border: 6px solid var(--f-v5);
    }
  }

  #services2 {
    .flip-card.inactive .front::before {
      background: linear-gradient(to bottom, var(--f-f6), var(--f-f7));
    }

    .front::before {
      background: linear-gradient(to bottom, var(--f-f7), var(--f-f8));
    }

    .back {
      border: 6px solid var(--f-f6);
    }
  }

  #softwares {
    .flip-card.inactive .front::before {
      background: linear-gradient(to bottom, var(--f-b6), var(--f-b7));
    }

    .front::before {
      background: linear-gradient(to bottom, var(--f-b7), var(--f-b8));
    }

    .back {
      border: 6px solid var(--f-b6);
    }
  }

  #commons {
    .flip-card.inactive .front::before {
      background: linear-gradient(to bottom, var(--f-r6), var(--f-r7));
    }

    .front::before {
      background: linear-gradient(to bottom, var(--f-r8), var(--f-r9));
    }

    .back {
      border: 6px solid var(--f-r6);
    }
  }

  #archipelago {
    .flip-card.inactive .front::before {
      background: linear-gradient(to bottom, var(--f-j6), var(--f-j8));
    }

    .front::before {
      background: linear-gradient(to bottom, var(--f-j6), var(--f-j8));
    }

    .back {
      border: 6px solid var(--f-j6);
    }
  }

  /* Highlighted cards */
  /*
  #flip-card-fun,
  #flip-card-docs,
  #flip-card-blog,
  #flip-card-yakforms,
  #flip-card-pad,
  #flip-card-chatons {
    animation: card-glow 2s linear infinite;
  }

  #flip-card-fun { color: var(--f-m6) }
  #flip-card-docs { color: var(--f-g6) }
  #flip-card-blog { color: var(--f-r6) }
  #flip-card-yakforms { color: var(--f-b6) }
  #flip-card-pad { color: var(--f-v5) }
  #flip-card-chatons { color: var(--f-j7) }
  */
}

#progress-banner {
  display: block;
  order: 6;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-top: 1rem;

  .banner {
    border: 5px solid var(--f-g7);
    border-left: none;
    border-right: none;
    position: relative;
    height: 240px;
    width: 100%;

    @media (min-width: 768px) {
      height: 420px;
    }
  }

  .active, .inactive {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    div {
      width: 100vw;
      height: 100%;
      background-image: url('../../../../public/img/campaign/2021/banner.jpg');
      background-size: cover;
      background-position: center;
    }
  }

  .inactive {
    filter: grayscale(.85);
  }

  .active {
    border-right: 2px solid var(--f-g7);
    box-shadow: 4px 0px 15px rgba(0, 0, 0, 0.7);
    transition: width .1s linear;
    z-index: 1;
  }

  .animate {
    transition: width 1s ease-in-out;
  }

  .slider {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    display: block;
    margin-left: -12px;
    margin-right: -12px;
    margin-top: 3px;
    outline: none;
    width: calc(100% + 24px);
    z-index: 2;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      background: transparent;
      border: 12px solid var(--f-g7);
      border-top: 0 solid transparent;
      border-left-color: transparent;
      border-right-color: transparent;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    &::-moz-range-thumb {
      background: transparent;
      border: 12px solid var(--f-g7);
      border-top: 0 solid transparent;
      border-left-color: transparent;
      border-right-color: transparent;
      cursor: pointer;
      height: 0;
      width: 0;
    }
  }
}

@keyframes card-glow {
  0% {
    filter: drop-shadow(0 0 3px currentColor);
  }
  50% {
    filter: drop-shadow(0 0 13px currentColor);
  }
}
</style>
